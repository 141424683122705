define('tomato/components/player-results-kpis', ['exports', 'ember', 'tomato/mixins/chart-utilities', 'lodash/lodash'], function (exports, _ember, _tomatoMixinsChartUtilities, _lodashLodash) {

  function getDataForPosition(position, averageKPIPerPlayer) {
    return [averageKPIPerPlayer[position]];
  }

  function getColorForPosition(position) {
    var hue = 9 + 200 * (position - 1);
    var saturation = 100;
    var lightness = 64;

    return 'hsl(' + hue + ',' + saturation + '%,' + lightness + '%)';
  }

  function getBordersForPosition(position) {
    var hue = 9 + 200 * (position - 1);
    var saturation = 100;
    var lightness = 44;

    return 'hsl(' + hue + ',' + saturation + '%,' + lightness + '%)';
  }

  exports['default'] = _ember['default'].Component.extend(_tomatoMixinsChartUtilities['default'], {
    i18n: _ember['default'].inject.service(),

    minimumAverageKPI: -1.0,
    maximumAverageKPI: 1.0,

    quantumState: false,

    calculateAverageKPIBounds: _ember['default'].on("init", _ember['default'].observer("role", "players", "positionsForPlayers", "averageKPIPerPlayer", function () {
      var role = this.get("role");
      var players = this.get("players");

      var positionsForPlayers = this.get("positionsForPlayers");
      var averageKPIPerPlayer = this.get("averageKPIPerPlayer");

      var minimumAverageKPI = -1.0;
      var maximumAverageKPI = 1.0;

      if (players.length > 0) {
        (function () {
          var minimum = Number.MAX_VALUE;
          var maximum = -Number.MAX_VALUE;

          players.forEach(function (player) {
            var position = player.get("playerPosition");

            var playerPosition = positionsForPlayers[role][position];

            var average = averageKPIPerPlayer[playerPosition];

            if (average < minimum) {
              minimum = average;
            }

            if (average > maximum) {
              maximum = average;
            }
          });

          minimumAverageKPI = _lodashLodash['default'].min([0.0, minimum]);
          maximumAverageKPI = _lodashLodash['default'].max([0.0, maximum]);
        })();
      }

      this.set("minimumAverageKPI", minimumAverageKPI);
      this.set("maximumAverageKPI", maximumAverageKPI);

      // Force the chart to update.
      this.set("quantumState", !this.get("quantumState"));
    })),

    data: _ember['default'].computed("i18n.locale", "role", "players", "positionsForPlayers", "averageKPIPerPlayer", function () {
      var i18n = this.get("i18n");

      var role = this.get("role");
      var players = this.get("players");

      var positionsForPlayers = this.get("positionsForPlayers");
      var averageKPIPerPlayer = this.get("averageKPIPerPlayer");

      var dataSets = players.map(function (player) {
        var position = player.get("playerPosition");

        var labels = [i18n.t("player.results.kpis." + role + ".role"), position];

        var label = labels.join(i18n.t("player.results.kpis.space"));

        var playerPosition = positionsForPlayers[role][position];

        var data = getDataForPosition(playerPosition, averageKPIPerPlayer);
        var colors = getColorForPosition(position);
        var borders = getBordersForPosition(position);

        return this.createBarChartDataSet(label, data, colors, borders);
      }, this);

      var labels = [""];

      return this.createChartData(dataSets, labels);
    }),

    options: _ember['default'].computed("i18n.locale", "minimumAverageKPI", "maximumAverageKPI", function () {
      var i18n = this.get("i18n");

      var minimumAverageKPI = this.get("minimumAverageKPI");
      var maximumAverageKPI = this.get("maximumAverageKPI");

      return {
        // These options attempt to force negative barcharts to include the zero line.
        scales: {
          yAxes: [{
            ticks: {
              min: minimumAverageKPI * 1.1,
              max: maximumAverageKPI * 1.1
            }
          }]
        },
        // These options format the tool tip value to something readable.
        tooltips: {
          callbacks: {
            label: function label(toolTipItem, data) {
              var label = data.datasets[toolTipItem.datasetIndex].label;
              var value = toolTipItem.yLabel;

              // Graciously stolen from the digit-format helper.
              value = parseFloat(Math.round(value * 100) / 100).toFixed(2);

              return label + i18n.t("chart.toolTipSeparator") + value;
            }
          }
        }
      };
    })
  });
});