define('tomato/components/tutorial-navigation', ['exports', 'ember', 'tomato/mixins/tutorial-actions', 'tomato/helpers/tutorial-state', 'ember-keyboard'], function (exports, _ember, _tomatoMixinsTutorialActions, _tomatoHelpersTutorialState, _emberKeyboard) {
  exports['default'] = _ember['default'].Component.extend(_tomatoMixinsTutorialActions['default'], _emberKeyboard.EKMixin, _emberKeyboard.EKOnInsertMixin, {
    tagName: "",

    leftArrowKeyPress: _ember['default'].on((0, _emberKeyboard.keyPress)("ArrowLeft"), function () {
      // Duplicates controller logic, but what are you gonna do...
      var tutorial = this.get("tutorial");
      var previous = this.get("previous");

      if (previous !== null) {
        if (typeof previous !== 'undefined') {
          var state = (0, _tomatoHelpersTutorialState.tutorialState)([tutorial, previous]);

          this.send("changeTutorialState", tutorial, state);
        } else {
          this.send("recedeTutorialState", tutorial);
        }
      }
    }),

    rightArrowKeyPress: _ember['default'].on((0, _emberKeyboard.keyPress)("ArrowRight"), function () {
      // Duplicates controller logic, but what are you gonna do...
      var tutorial = this.get("tutorial");
      var next = this.get("next");

      if (next !== null) {
        if (typeof next !== 'undefined') {
          var state = (0, _tomatoHelpersTutorialState.tutorialState)([tutorial, next]);

          this.send("changeTutorialState", tutorial, state);
        } else {
          this.send("advanceTutorialState", tutorial);
        }
      }
    })
  });
});