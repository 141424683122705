define('tomato/components/load-xlsx-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // Have the component wrap the file input with a label so we can make it look and act like a button.
    tagName: 'label',

    change: function change(event) {
      var self = this;

      var file = event.target.files[0];

      if (!file) {
        console.warn("No file was selected.");

        return;
      }

      var reader = new FileReader();

      reader.onload = function (event) {
        var workBook = XLSX.read(event.target.result, { type: "binary" });
        var workSheet = workBook.Sheets[workBook.SheetNames[0]];

        // Convert it to an array of objects so we don't have to work with the Excel sheet outside of this component.
        var rows = XLSX.utils.sheet_to_json(workSheet);

        self.sendAction("action", rows);
      };

      reader.readAsBinaryString(file);
    }
  });
});