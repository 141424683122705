define('tomato/services/tutorial-state', ['exports', 'ember', 'lodash/lodash'], function (exports, _ember, _lodashLodash) {
  exports['default'] = _ember['default'].Service.extend({
    tutorialState: _ember['default'].Object.create(),

    quantumState: false,

    getTutorialState: function getTutorialState(tutorial) {
      var tutorialState = this.get('tutorialState');

      if (_lodashLodash['default'].has(tutorialState, tutorial)) {
        return tutorialState[tutorial];
      } else {
        return 0;
      }
    },

    setTutorialState: function setTutorialState(tutorial, state) {
      var tutorialState = this.get('tutorialState');

      tutorialState[tutorial] = state;

      this.set('quantumState', !this.get('quantumState'));
    },

    decrementTutorialState: function decrementTutorialState(tutorial) {
      var tutorialState = this.get('tutorialState');

      if (!_lodashLodash['default'].has(tutorialState, tutorial)) {
        tutorialState[tutorial] = 0;
      }

      tutorialState[tutorial] -= 1;

      this.set('quantumState', !this.get('quantumState'));
    },

    incrementTutorialState: function incrementTutorialState(tutorial) {
      var tutorialState = this.get('tutorialState');

      if (!_lodashLodash['default'].has(tutorialState, tutorial)) {
        tutorialState[tutorial] = 0;
      }

      tutorialState[tutorial] += 1;

      this.set('quantumState', !this.get('quantumState'));
    }
  });
});