define("tomato/controllers/index", ["exports", "ember", "tomato/mixins/table-utilities"], function (exports, _ember, _tomatoMixinsTableUtilities) {
  exports["default"] = _ember["default"].Controller.extend(_tomatoMixinsTableUtilities["default"], {
    columns: _ember["default"].computed("i18n.locale", function () {
      return [{
        "propertyName": "gameName",
        "title": this.localize("index.name"),
        "template": "custom/game-link-cell"
      }, {
        "propertyName": "ts",
        "title": this.localize("index.date"),
        "template": "custom/moment-date-time-column",

        // Sort on descending moment of creation by default, so new games are at the top of the list.
        "sortPrecedence": 1000,
        "sortDirection": "desc"
      }, {
        "propertyName": "id",
        "title": this.localize("index.id"),
        "template": "custom/game-link-cell"
      }, {
        "title": this.localize("index.remove"),
        "template": "custom/game-remove-cell"
      }];
    }),

    actions: {
      addGame: function addGame() {
        var gameName = undefined;

        do {
          gameName = prompt(this.localize("index.prompt"));

          if (gameName === null) {
            return;
          }
        } while (!gameName);

        this.store.createRecord('game', { gameName: gameName }).save();
      },

      removeGame: function removeGame(game) {
        game.set("_aboutToDelete", true);
      },

      confirmRemoveGame: function confirmRemoveGame(game) {
        game.destroyRecord();
      },

      cancelRemoveGame: function cancelRemoveGame(game) {
        game.set("_aboutToDelete", false);
      }
    }
  });
});