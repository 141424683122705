define('tomato/router', ['exports', 'ember', 'tomato/config/environment'], function (exports, _ember, _tomatoConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _tomatoConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('player', { path: '/player/:player_id' });
    this.route('games', { path: '/game/:game_id' });
    this.route('historical', { path: '/game/:game_id/history' });
  });

  exports['default'] = Router;
});