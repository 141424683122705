define('tomato/components/tom-clock', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    m: 0.0, // min (0-60)
    h: 9.0, // hour (0-24) e.g. 9h - 17h = 8h

    timeStartTs: null,
    timeEndTs: null,

    killTimer: _ember['default'].on("willDestroyElement", function () {
      clearInterval(this.get("timerHandle"));
    }),

    test: _ember['default'].on("didInsertElement", function () {
      var self = this;

      var timerHandle = setInterval(function () {
        if (!self.get("timeStartTs") && !self.get("timeEndTs")) {
          return;
        }

        var nowMoment = (0, _moment['default'])();
        var endMoment = (0, _moment['default'])(self.get("timeEndTs"));
        self.set("timeMissingMin", endMoment.diff(nowMoment, 'minutes'));
        self.set("timeMissingSec", endMoment.diff(nowMoment, 'seconds'));

        // This works because the duration will never be more than 5 minutes.
        var duration = _moment['default'].duration(endMoment.diff(nowMoment));
        self.set("remainingTime", _moment['default'].utc(duration.asMilliseconds()).format("m:ss"));

        var currDiff = (0, _moment['default'])() - self.get("timeStartTs");
        var totalDiff = self.get("timeEndTs") - self.get("timeStartTs"); // turn ms into min

        var ratio = currDiff / totalDiff;
        ratio = Math.min(ratio, 1.0);

        self.set("isOver", ratio === 1.0);

        var ratioMinutes = ratio * 8 * 60;

        var mAdd = ratioMinutes;
        var hAdd = ratioMinutes / 60;

        self.set("m", mAdd);
        self.set("h", 9 + hAdd);
      }, 1000);

      this.set("timerHandle", timerHandle);
    }),

    timeMissingAlert: _ember['default'].computed("timeMissingSec", function () {
      return +this.get("timeMissingSec") < 60;
    }),

    hDeg: _ember['default'].computed("h", "m", function () {
      return 360.0 * this.get("h") * (1.0 / 12.0);
    }),

    mDeg: _ember['default'].computed("m", function () {
      return 360.0 * this.get("m") * (1.0 / 60.0);
    })
  });
});