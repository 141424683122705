define("tomato/components/player-results-player-properties", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    averageKPI: _ember["default"].computed("averageKPIPerPlayer", "player", "positionsForPlayers", function () {
      var averageKPIPerPlayer = this.get("averageKPIPerPlayer");

      var player = this.get("player");

      var positionsForPlayers = this.get("positionsForPlayers");

      var role = player.get("roleDescription");
      var position = player.get("playerPosition");

      position = positionsForPlayers[role][position];

      return averageKPIPerPlayer[position];
    }),

    isWinner: _ember["default"].computed("averageKPIPerPlayer", "player", "buyers", "sellers", "positionsForPlayers", function () {
      var averageKPIPerPlayer = this.get("averageKPIPerPlayer");

      var player = this.get("player");

      var buyers = this.get("buyers");
      var sellers = this.get("sellers");

      var positionsForPlayers = this.get("positionsForPlayers");

      var playerPosition = player.get("playerPosition");
      var playerIsSeller = player.get("isSeller");

      var contenders = playerIsSeller ? sellers : buyers;

      var maximum = -Number.MAX_VALUE;
      var winnerPosition = -1;

      contenders.forEach(function (contender) {
        var contenderRole = contender.get("roleDescription");
        var contenderPosition = contender.get("playerPosition");

        var position = positionsForPlayers[contenderRole][contenderPosition];

        var average = averageKPIPerPlayer[position];

        if (average > maximum) {
          maximum = average;

          winnerPosition = contenderPosition;
        }
      });

      return playerPosition === winnerPosition;
    })
  });
});