define("tomato/routes/player", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    i18n: _ember["default"].inject.service(),

    title: function title() {
      return this.get("i18n").t("index.title");
    },

    model: function model(p) {
      return this.store.find('user', p.player_id);
    }
  });
});