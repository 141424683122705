define('tomato/helpers/eq-num', ['exports', 'ember'], function (exports, _ember) {
  exports.eqNum = eqNum;

  function eqNum(params /*, hash*/) {
    var prm0 = params[0];
    var prm1 = params[1];
    return +prm0 === +prm1;
  }

  exports['default'] = _ember['default'].Helper.helper(eqNum);
});