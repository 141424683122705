define("tomato/locales/nl/translations", ["exports"], function (exports) {
  exports["default"] = {
    "index": {
      "title": "Tomato Trade",
      "add": "Create a new game",
      "prompt": "Please enter a name for the game:",
      "name": "Name",
      "date": "Date",
      "id": "ID",
      "remove": "Verwijderen",
      "confirm": "Bevestigen",
      "cancel": "Annuleren"
    },
    "games": {
      "startGame": "Start game",
      "endGame": "End game",
      "nextRound": "Next round",
      "resume": "Resume",
      "pause": "Pause",
      "restartRound": "Restart round",
      "endRoundIn5Minutes": "End round in 5 minutes",
      "endRoundIn1Minute": "End round in 1 minute",
      "endRoundNow": "End round now",
      "endGameNow": "End game now",
      "globalTrading": "Global trading",
      "globalTradingSchedule": "Global trading schedule:",
      "saveGlobalTradingSchedule": "Save global trading schedule",
      "configuration": "Configuration",
      "history": "History",
      "revertSettings": "Revert settings",
      "applySettings": "Apply settings",
      "settings": "Settings",
      "name": "Name:",
      "round": "Ronde:",
      "week": "Week:",
      "minutesPerRound": "Minutes per round:",
      "fine": "Boete:",
      "fixedCosts": "Vaste kosten:",
      "retailPrice": "Retailprijs:",
      "tradeRound": "Ronde {{round}}:",
      "weekly": "Wekelijkse",
      "daily": "Dagelijkse",
      "gameWasDeleted": "GAME WAS DELETED, PLEASE CHECK",
      "playerIDs": "Players",
      "numberOfSellers": "Cooperatives",
      "numberOfBuyers": "Traders",
      "numberOfRounds": "Rounds",
      "saveConfiguration": "Save",
      "updateConfiguration": "Update",
      "revertConfiguration": "Revert",
      "cancelConfiguration": "Cancel",
      "facilitator": "Facilitator",
      "confirmation": "This will cause everything to start from scratch. Are you sure you wish to change the configuration?"
    },
    "history": {
      "title": "History",
      "needsReload": "Please reload the page, so the indices can be calculated consistently",
      "import": "Import",
      "export": "Export",
      "round": "Ronde",
      "calculatedOfferID": "Calculated offer ID",
      "offerID": "Offer ID",
      "sender": "Sender",
      "receiver": "Receiver",
      "status": "Status",
      "offer": "Bod",
      "tomatoes": "Tomaten",
      "unitPrice": "Unit price",
      "time": "Time",
      "confirm": "This will replace the existing history. Are you sure you wish to continue?"
    },
    "player": {
      "external": "Externe partij",
      "buyer": "Handelshuis",
      "seller": "Coöperatie",
      "offerExceeds3StandingOffers": "U kunt niet meer dan 3 lopende onderhandelingen tegelijkertijd hebben.",
      "offerExceedsRemainder": "U kunt niet meer handelen dan uw restant.",
      "amount": "Amount:",
      "externalOfferMaximum": "Maximum of",
      "amountPlaceholder": "kilogram",
      "unitPrice": "Unit price:",
      "unitPricePlaceholder": "prijs per kilogram",
      "send": "Sturen",
      "currentTransactions": "Lopende onderhandelingen",
      "last5Transactions": "Laatste 5 onderhandelingen",
      "noTransactions": "There are no transactions.",
      "previouslyAcceptedOffers": "Eerder geaccepteerde biedingen",
      "noExternalTransactions": "There are no external transactions.",
      "buyerProperties": {
        "demand": "Demand",
        "bought": "Bought",
        "remainder": "Restant",
        "averageUnitPrice": "Gemiddelde prijs",
        "fine": "Boete",
        "fixedCosts": "Vaste kosten",
        "retailPrice": "Retailprijs"
      },
      "sellerProperties": {
        "supply": "Supply",
        "sold": "Sold",
        "remainder": "Restant",
        "averageUnitPrice": "Gemiddelde prijs"
      },
      "offers": {
        "offerExceedsRemainder": "U kunt niet meer handelen dan uw restant.",
        "for": "for",
        "accept": "Accepteren",
        "confirm": "Bevestigen",
        "decline": "Afwijzen",
        "recallOpen": "Intrekken",
        "recallAccept": "Intrekken",
        "pending": "In behandeling...",
        "noTransactions": "There are no transactions."
      },
      "offerDisplay": {
        "round": "ronde",
        "for": "for",
        "accepted": "aanvaard",
        "declined": "afgewezen",
        "recalled": "ingetrokken",
        "external": "Externe partij"
      },
      "overview": {
        "activate": "Activate",
        "save": "Save",
        "addPrognosis": "Add prognosis",
        "name": "Name:",
        "supply": "Supply:",
        "demand": "Demand:",
        "bought": "Bought:",
        "sold": "Sold:",
        "externalTradingEnabled": "External trading enabled",
        "externalTradingDisabled": "External trading disabled",
        "amount": "Amount:",
        "amountPlaceholder": "kilogram",
        "unitPrice": "Unit price:",
        "unitPricePlaceholder": "prijs per kilogram",
        "sendExternalOffer": "Send external offer",
        "money": "Geld:",
        "averageUnitPrice": "Gemiddelde stuksprijs:",
        "kpi": "KPI:"
      },
      "results": {
        "kpis": {
          "buyer": {
            "title": "Gemiddelde stukswinsten",
            "role": "Handelshuis"
          },
          "seller": {
            "title": "Gemiddelde stuksprijzen",
            "role": "Coöperatie"
          },
          "space": " "
        },
        "player": {
          "buyer": {
            "averageUnitProfit": "Gemiddelde stukswinst"
          },
          "seller": {
            "averageUnitPrice": "Gemiddelde stuksprijs"
          },
          "kpis": {
            "title": {
              "buyer": "Stukswinsten",
              "seller": "Stuksprijzen"
            },
            "buyer": "Handelshuis",
            "seller": "Coöperatie",
            "week": "Week",
            "space": " "
          },
          "transactions": {
            "title": "Transactions",
            "open": "Open",
            "declined": "Declined",
            "accepted": "Accepted",
            "confirmed": "Confirmed",
            "recalled": "Recalled",
            "weekly": "Weekly",
            "daily": "Daily",
            "to": "To",
            "from": "From",
            "buyer": "handelshuis",
            "seller": "coöperatie",
            "space": " ",
            "week": "Week:"
          }
        },
        "transactions": {
          "best": {
            "title": "Best transactions",
            "buyerTitle": "Best trader transactions",
            "sellerTitle": "Best cooperative transactions",
            "buyer": "Handelshuis",
            "seller": "Coöperatie",
            "external": "Externe partij"
          },
          "all": {
            "title": "All transactions",
            "buyer": "Handelshuis",
            "seller": "Coöperatie",
            "external": "Externe partij"
          },
          "round": "Ronde",
          "sender": "Sender",
          "receiver": "Receiver",
          "amount": "Amount",
          "unitPrice": "Unit price"
        }
      }
    },
    "timer": {
      "trade": "Handel",
      "week": "Week",
      "time": "Tijd",
      "weekly": "Wekelijkse",
      "daily": "Dagelijkse",
      "imported": "Geïmporteerd",
      "tutorial": "Tutorial",
      "pending": "In afwachting",
      "paused": "Gepauzeerd",
      "gameOver": "Afgelopen!",
      "timeUp": "Tijd om!"
    },
    "tutorial": {
      "player": {
        "introduction1": "<h3 class='title-container'>Welcome to Tomato Trade!</h3><p class='text-center'><img src='assets/images/logos/inholland.png' class='image-logo' /><img src='assets/images/logos/samenmarkt.png' class='image-logo' /><img src='assets/images/logos/tudelft.png' class='image-logo' /></p><p class='text-center'>Use the arrows at the right side of these messages to move back and forth through the tutorial.</p>",
        "introduction2": "This tutorial aims to make you familiar with the concepts of the <strong>tomato trade</strong>, and the <strong>flow</strong> and <strong>functionality</strong> of this game.",
        "trade1": "<strong>SamenMarkt®</strong> is an initiative of various growers, traders, and researchers in the Netherlands. Its goal is to provide a <strong>better future</strong> for the horticulture sector in the Netherlands, through <strong>transparant pricing</strong>, <strong>rationality</strong>, <strong>stability</strong>, and <strong>trust</strong>. This game was made and is played as part of this effort, allowing research into and training of digital forms of trading.",
        "trade2": "<p>The tomato trade in the Netherlands roughly consists of a chain of four parties: <strong>growers</strong>, <strong>cooperatives</strong>, <strong>traders</strong>, and <strong>retailers</strong>. Tomatoes travel from the growers to the retailers, and money travels back from the retailers to the growers.</p><h3 class='title-container'>Growers<span class='glyphicon glyphicon-transfer tutorial-icon'></span>Cooperatives<span class='glyphicon glyphicon-transfer tutorial-icon'></span>Traders<span class='glyphicon glyphicon-transfer tutorial-icon'></span>Retailers</h3><p>This game focuses on the trade between <strong>cooperatives</strong> and <strong>traders</strong>. Cooperatives trade on behalf of the growers to negotiate a price that is as <strong>high</strong> as possible. Traders, on the other hand, trade on behalf of the retailers and wish to negotiate a price that is as <strong>low</strong> as possible in order to maximize their profits (the retail price is known in advance).</p>The other trades, between growers and cooperatives, and between traders and retailers, are known in advance and are represented in this game as the <strong>supply</strong> and the <strong>demand</strong>, respectively.",
        "game1": "<p>The game is based around the trade between <strong>cooperatives</strong> and <strong>traders</strong>, and has you play as one of either role. There can be several players of each role.</p>Trading is done per week, and for each week the game will have you perform the <strong>weekly trade</strong> (the regular trade) and the <strong>daily trade</strong> (the last-minute trade) for the remaining tomatoes. Each of these types of trades is performed in a <strong>round</strong> of the game, which means that each week consists of two rounds (the weekly trade round and the daily trade round).",
        "game2": "The <strong>goal</strong> of the game is to <strong>negotiate contracts</strong> for the delivery of tomatoes with the players of the opposite role, in order to <strong>perform better</strong> than the players with the same role as you by <strong>scoring a better average</strong> taken over the entire game.",
        "game3": "<p>Take note that both the <strong>supply</strong> and <strong>demand</strong> are only <strong>prognoses</strong> of the definitive amount. Only after the weekly trade, the amount you have to trade for will be made definitive by adjusting it with a random value. You will have to account for this possible change during both the weekly and the daily trade.</p>The prognosis can change by either <strong>0%</strong>, <strong>5%</strong>, or <strong>10%</strong>, in <strong>either direction</strong>. Each percentage is <strong>equally likely</strong> to occur, with <strong>0% twice as likely</strong> to occur.",
        "anchor1": "Now that you've learned a little about the tomato trade, let's talk about your <strong>role</strong>.",
        "sellerIntroduction": "<p>You are playing a <strong>cooperative</strong>. Your job is to <strong>sell tomatoes</strong> to traders on behalf of the growers.</p>Your <strong>goal</strong> is achieve the <strong>highest possible unit price</strong> per sale, and the <strong>highest average unit price</strong> over the entire game.",
        "sellerAnchor": "Now that you know what a cooperative does, let's take a quick look at the <strong>information</strong> available to cooperatives.",
        "sellerPropertiesIntroduction": "These values represent your <strong>progress</strong> as a cooperative for the week. Their initial values depend on the scenario you are playing, and they will change as you perform successful trades.",
        "sellerPropertySupply": "This is the <strong>supply of tomatoes</strong> (in kilograms) that you are expected to sell on behalf of the farmers this week.",
        "sellerPropertySold": "This is the amount of tomatoes (in kilograms) that you have <strong>sold</strong> so far this week.",
        "sellerPropertyRemainder": "This is the amount of tomatoes (in kilograms) that you <strong>still have to sell</strong> this week.",
        "sellerPropertyAverageUnitPrice": "This is the <strong>average unit price</strong> (per kilogram) of all the trades that you have successfully performed this week.",
        "sellerPropertiesConclusion": "And make sure to sell all of your supply. Any remaining tomatoes will <strong>spoil</strong> and have to be <strong>dumped</strong>. Remember that the supply can change with up to <strong>10%</strong> and will only be made definitive at the start of the <strong>daily trade</strong>.",
        "sellerConclusion": "Keep an eye on those values. They are important to your goal of achieving the <strong>highest average unit price</strong> over the entire game.",
        "buyerIntroduction": "<p>You are playing a <strong>trader</strong>. Your job is to <strong>buy tomatoes</strong> from cooperatives so you can sell them to the retailers (for the retail price that is known in advance). Note that you incur fixed costs every week, and that you will have to pay a fine if you do not meet the demand of the retailers.</p>Your <strong>goal</strong> is achieve the <strong>highest possible profit</strong>, and the <strong>highest average profit</strong> per unit of tomato over the entire game.",
        "buyerAnchor": "Now that you know what a trader does, let's take a quick look at the <strong>information</strong> available to traders.",
        "buyerPropertiesIntroduction1": "These values represent your <strong>progress</strong> as a trader for the week. Their initial values depend on the scenario you are playing, and they will change as you perform successful trades.",
        "buyerPropertyDemand": "This is the <strong>demand of tomatoes</strong> (in kilograms) that you are expected to buy on behalf of the retailers this week.",
        "buyerPropertyBought": "This is the amount of tomatoes (in kilograms) that you have <strong>bought</strong> so far this week.",
        "buyerPropertyRemainder": "This is the amount of tomatoes (in kilograms) that you <strong>still have to buy</strong> this week.",
        "buyerPropertyAverageUnitPrice": "This is the <strong>average unit price</strong> (per kilogram) of all the trades you have successfully performed this week.",
        "buyerPropertiesConclusion1": "And make sure you completely fulfill the demand each week. If you don't, you will be <strong>fined</strong> for the remaining demand. Remember that the demand can change with up to <strong>10%</strong> and this will only be made definitive at the start of the <strong>daily trade</strong>. Also note that if you buy too many tomatoes, the surplus will <strong>spoil</strong> and has to be <strong>dumped</strong>.",
        "buyerPropertiesIntroduction2": "These values represent your <strong>relationship with the retailers</strong> for this round. Their values depend on the scenario you are playing, and will remain fixed during the week.",
        "buyerPropertyFine": "This is the <strong>fine</strong> (per kilogram) that you have to pay for the tomatoes you failed to buy for the retailers this week.",
        "buyerPropertyFixedCosts": "These are the <strong>fixed costs</strong> (per kilogram) that you will incur this week. They are subtracted from your revenue to determine your profit this week.",
        "buyerPropertyRetailPrice": "This is the <strong>price</strong> (per kilogram) that the retailers will <strong>pay you</strong> for their tomatoes this week.",
        "buyerPropertiesConclusion2": "Keep in mind that your profit depends not only on the <strong>prices you negotiate</strong>, but also on the <strong>fixed costs</strong> and the <strong>retail price</strong>.",
        "buyerConclusion": "Keep an eye on those values. They are important to your goal of achieving the <strong>highest average profit</strong> per unit of tomato over the entire game.",
        "anchor2": "Let's take a look at the rest of the <strong>information</strong> available in the game.",
        "clockIntroduction": "These values will show you the <strong>current state</strong> of the game.",
        "clockPlayer": "Here you can see the <strong>name</strong> and <strong>role</strong> of your enterprise.",
        "clockTrade": "This is the <strong>type of trade</strong> you are performing this round. This alternates between weekly and daily trade for the week you are trading for.",
        "clockWeek": "This is the <strong>week</strong> you are trading for. Each week consists of two rounds, the weekly trade round and the daily trade round.",
        "clockTime": "This is the <strong>time</strong> you have for performing your trades. If the game is paused or otherwise not running, it will be shown here instead.",
        "clockConclusion": "Keep an eye on the clock. It will tick down until the <strong>time is up</strong>, at which point you are expected to <strong>finish any remaining trades</strong> so the next round can start. You have about <strong>7</strong> minutes to perform your trades.",
        "tradeIntroduction": "During the game, you will <strong>perform trades</strong> with other players. Let's take a look at how this works.",
        "tradeSend1": "Trading with another player consists of <strong>making a contract</strong> for the delivery of tomaties by <strong>offering</strong> an <strong>amount</strong> of tomatoes for a (unit) <strong>price</strong>.",
        "tradeSend2": "The other player then has the option to either <strong>decline</strong> or <strong>accept</strong> your offer. Let's try it now!",
        "tradeTutorial1": "Enter an amount of <span class='to-tomato'></span><strong>100</strong> and a unit price of <span class='to-money'></span><strong>1.00</strong> into the boxes, and then press the <strong>Send</strong> button.",
        "tradeTutorial2": "Your offer is now <strong>awaiting acceptance</strong> by the other player. You will find the pending offers that you have made over here.",
        "tradeTutorial3": "<p>Looks like the other player <strong>declined</strong> your offer (for tutorial purposes)!</p><p>You can find such offers here. The <strong>last 5</strong> transactions are visible here, including recalled and declined ones. You can use this information to <strong>adjust your trading</strong>.</p>Let's make a better offer.",
        "tradeTutorial4": "Enter an amount of <span class='to-tomato'></span><strong>100</strong> and a unit price of <span class='to-money'></span><strong>0.98</strong> into the boxes, and then press the <strong>Send</strong> button.",
        "tradeTutorial5": "The other player has already accepted your offer! You can now choose to either <strong>confirm</strong> this offer if you are still satisfied with it, or <strong>recall</strong> the offer if you no longer wish to trade. For tutorial purposes, confirm your offer by pressing the <strong>Confirm</strong> button.",
        "tradeTutorial6": "<p>You have successfully made a <strong>trade</strong>!</p>You can find your <strong>successful</strong> trades over here. This gives you an <strong>overview</strong> of the <strong>prices</strong> you have negotiated so far.",
        "tradeTutorialSeller1": "Notice also that the amount you just <strong>sold</strong> is now displayed here.",
        "tradeTutorialSeller2": "As well as the change in the <strong>remaining</strong> amount that you still have to sell. Your <strong>goal</strong> is to make enough successful transactions this week to get this amount to 0.",
        "tradeTutorialSeller3": "Finally, notice the change to the <strong>average unit price</strong>. Here you can see how well you have been doing this week.",
        "tradeTutorialBuyer1": "Notice also that the amount you just <strong>bought</strong> is now displayed here.",
        "tradeTutorialBuyer2": "As well as the change in the <strong>remaining</strong> amount that you still have to buy. Your <strong>goal</strong> is to make enough successful transactions this week to get this amount to 0.",
        "tradeTutorialBuyer3": "Finally, notice the change to the <strong>average unit price</strong>. Here you can see how well you have been doing this week.",
        "tradeTutorial7": "Looks like you <strong>received</strong> an offer from the other player! You now have the option to <strong>accept</strong> or <strong>decline</strong> this offer. If you accept the offer, the other player will still have to <strong>confirm</strong> the trade. For tutorial purposes, decline this offer by pressing the <strong>Decline</strong> button.",
        "tradeConclusion": "<p>Trading forms the core of the game, and is where you will have to effect your <strong>strategy</strong>.</p>Note that we have only tried a few <strong>weekly</strong> trades, there is still a <strong>daily</strong> trade round that comes after the weekly trade round. Fortunately, daily trades <strong>work exactly the same</strong> as weekly trades.",
        "externalTradeIntroduction": "During a round you may also have the option to trade with <strong>external parties</strong>. These are trade partners that are not represented by the players in the game. Let's take a look at how this works.",
        "externalTradeSend1": "Trading with external parties works the same as trading with other players. You <strong>enter the amount</strong> to trade for and <strong>request</strong> and later <strong>confirm</strong> or <strong>recall</strong> the trade.",
        "externalTradeSend2": "However, the unit price for trading with external parties is <strong>fixed</strong>. Additionally, you can only trade with external parties up to a shown <strong>maximum</strong>, but you are not required to trade for the fully available amount. You are free to trade for <strong>any amount</strong> of tomatoes up to and including the maximum.",
        "externalTradeConclusion": "Note that the option to trade with external parties only becomes available <strong>after trading for a few minutes</strong> and will likely <strong>not remain available</strong> for the entire remaining duration of the round, so keep that in mind when considering trading with external parties.",
        "conclusion1": "<p>After trading for all the weeks in the scenario, the game will <strong>end</strong>, and a <strong>results</strong> page will be shown. There you can see how you have performed compared to the other players.</p>Note that there will be <strong>two winners</strong>, both a cooperative and a trader. The best cooperative is the player with the <strong>highest average unit price</strong> among all cooperatives, and the best trader is the player with the <strong>highest average profit</strong> per unit of tomatoes among all traders.",
        "conclusion2": "<p class='text-center'>This is the end of the tutorial. If you have any doubts you can rewind through the tutorial and take a second look at various parts of the tutorial.</p><h3 class='title-container'>Good luck!</h3>",
        "values": {
          "tradeType": "Wekelijkse",
          "tradingFor": "1",
          "externalTradeMaximum": "100",
          "seller": {
            "supply1": "1000",
            "supply2": "1000",
            "sold1": "0",
            "sold2": "100",
            "remainder1": "1000",
            "remainder2": "900",
            "averageUnitPrice1": "0.00",
            "averageUnitPrice2": "0.98"
          },
          "buyer": {
            "demand1": "1000",
            "demand2": "1000",
            "bought1": "0",
            "bought2": "100",
            "remainder1": "1000",
            "remainder2": "900",
            "averageUnitPrice1": "0.00",
            "averageUnitPrice2": "0.98",
            "fine": "0.20",
            "fixedCosts": "0.05",
            "retailPrice": "1.20"
          }
        }
      }
    },
    "chart": {
      "toolTipSeparator": ": "
    },
    "table": {
      "tableSummary": "Displaying %@ through %@ out of the %@ entries",
      "noDataToShow": "There are no entries available."
    }
  };
});