define("tomato/initializers/chart-background", ["exports"], function (exports) {
  exports.initialize = initialize;

  function initialize() /* application */{
    // http://stackoverflow.com/questions/37144031/background-colour-of-line-charts-in-chart-js
    Chart.pluginService.register({
      beforeDraw: function beforeDraw(chart, easing) {
        var ctx = chart.chart.ctx;
        var chartArea = chart.chartArea;

        ctx.save();
        ctx.fillStyle = "rgba(255, 255, 255, 1)";
        ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
        ctx.restore();
      }
    });
  }

  exports["default"] = {
    name: 'chart-background',
    initialize: initialize
  };
});