define('tomato/components/tutorial-row', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    tutorialState: _ember['default'].inject.service(),

    // Using not one but two hacks! 'tutorialState.quantumState' is toggled every time
    // 'tutorialState.tutorialState' has changed, and 'tutorial' comes from the calling template.
    state: _ember['default'].computed('tutorialState.tutorialState', 'tutorialState.quantumState', function () {
      return this.get('tutorialState').getTutorialState(this.get('tutorial'));
    })
  });
});