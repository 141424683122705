define('tomato/controllers/player', ['exports', 'ember', 'tomato/mixins/offer-actions', 'tomato/mixins/lang-actions', 'tomato/mixins/tutorial-actions', 'moment'], function (exports, _ember, _tomatoMixinsOfferActions, _tomatoMixinsLangActions, _tomatoMixinsTutorialActions, _moment) {

  var lastRound = 1;

  exports['default'] = _ember['default'].Controller.extend(_tomatoMixinsOfferActions['default'], _tomatoMixinsLangActions['default'], _tomatoMixinsTutorialActions['default'], {
    game: _ember['default'].computed.alias("model.userGame.content"),
    _extOfferTomato: 0,

    clearInputsOnNewRound: _ember['default'].observer("game.roundCnt", function () {
      var roundCnt = this.get('game.roundCnt');

      if (roundCnt === lastRound) {
        return;
      }

      lastRound = roundCnt;

      this.set("_ext_offerTomato", "");

      // Prevents an error when you reset the configuration.
      if (this.get("game.users")) {
        this.get("game.users").forEach(function (u) {
          u.set("_offerTomato", "");
          u.set("_offerPrice", "");
        });
      }
    }),

    isTutorialActive: _ember['default'].computed.alias("game.gameIsAboutToStart"),

    // Duplicated from tutorial-range.js to prevent the largest code duplication ever to be seen by mankind.
    playerTutorialState: _ember['default'].computed("tutorialState.tutorialState", "tutorialState.quantumState", function () {
      return this.get("tutorialState").getTutorialState("player");
    }),

    tutorialOffer1Open: _ember['default'].computed("model.name", function () {
      return _ember['default'].Object.create({
        ts: (0, _moment['default'])(),
        roundNumber: 0,
        senderName: this.get("model.name"),
        receiverName: "Tutorial",
        tomatoes: 100,
        price: 1.0,
        state: "open"
      });
    }),

    tutorialOffer1Declined: _ember['default'].computed("model.name", function () {
      return _ember['default'].Object.create({
        ts: (0, _moment['default'])(),
        roundNumber: 0,
        senderName: this.get("model.name"),
        receiverName: "Tutorial",
        tomatoes: 100,
        price: 1.0,
        state: "declined"
      });
    }),

    tutorialOffer2Confirmed: _ember['default'].computed("model.name", function () {
      return _ember['default'].Object.create({
        ts: (0, _moment['default'])(),
        roundNumber: 0,
        senderName: this.get("model.name"),
        receiverName: "Tutorial",
        tomatoes: 100,
        price: 0.98,
        state: "confirmed",
        isConfirmed: true
      });
    }),

    tutorialOffer2Accepted: _ember['default'].computed("model.name", function () {
      return _ember['default'].Object.create({
        ts: (0, _moment['default'])(),
        roundNumber: 0,
        senderName: this.get("model.name"),
        receiverName: "Tutorial",
        tomatoes: 100,
        price: 0.98,
        state: "accepted"
      });
    }),

    tutorialOffer3Open: _ember['default'].computed("model.name", function () {
      return _ember['default'].Object.create({
        ts: (0, _moment['default'])(),
        roundNumber: 0,
        senderName: "Tutorial",
        receiverName: this.get("model.name"),
        tomatoes: 60,
        price: 0.75,
        state: "open"
      });
    }),

    tutorialOffer3Declined: _ember['default'].computed("model.name", function () {
      return _ember['default'].Object.create({
        ts: (0, _moment['default'])(),
        roundNumber: 0,
        senderName: "Tutorial",
        receiverName: this.get("model.name"),
        tomatoes: 60,
        price: 0.75,
        state: "declined"
      });
    }),

    tutorialOpenOffersEmpty: [],
    tutorialSentOffersEmpty: [],

    tutorialOpenOffers1: _ember['default'].computed("tutorialOffer3Open", function () {
      return [this.get("tutorialOffer3Open")];
    }),

    tutorialSentOffers1: _ember['default'].computed("tutorialOffer1Open", function () {
      return [this.get("tutorialOffer1Open")];
    }),

    tutorialSentOffers2: _ember['default'].computed("tutorialOffer2Confirmed", function () {
      return [this.get("tutorialOffer2Confirmed")];
    }),

    tutorialLast5Transactions1: _ember['default'].computed("tutorialOffer1Declined", function () {
      return [this.get("tutorialOffer1Declined")];
    }),

    tutorialLast5Transactions2: _ember['default'].computed("tutorialOffer1Declined", "tutorialOffer2Accepted", function () {
      return [this.get("tutorialOffer2Accepted"), this.get("tutorialOffer1Declined")];
    }),

    tutorialLast5Transactions3: _ember['default'].computed("tutorialOffer1Declined", "tutorialOffer2Accepted", "tutorialOffer3Declined", function () {
      return [this.get("tutorialOffer3Declined"), this.get("tutorialOffer2Accepted"), this.get("tutorialOffer1Declined")];
    }),

    previouslyAcceptedOffers1: _ember['default'].computed("tutorialOffer2Accepted", function () {
      return [this.get("tutorialOffer2Accepted")];
    })
  });
});