define('tomato/helpers/tutorial-last-state', ['exports', 'ember', 'tomato/tutorials/tutorials'], function (exports, _ember, _tomatoTutorialsTutorials) {
  exports.tutorialLastState = tutorialLastState;

  function tutorialLastState(params /*, hash*/) {
    var tutorial = params[0];

    var states = _tomatoTutorialsTutorials['default'][tutorial];

    return states.length - 1;
  }

  exports['default'] = _ember['default'].Helper.helper(tutorialLastState);
});