define("tomato/routes/application", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    i18n: _ember["default"].inject.service(),

    moment: _ember["default"].inject.service(),

    title: function title() {
      return this.get("i18n").t("index.title");
    },

    beforeModel: function beforeModel() {
      this.get('moment').changeLocale('nl');
    }
  });
});