define('tomato/helpers/tutorial-state', ['exports', 'ember', 'lodash/lodash', 'tomato/tutorials/tutorials'], function (exports, _ember, _lodashLodash, _tomatoTutorialsTutorials) {
  exports.tutorialState = tutorialState;

  function tutorialState(params /*, hash*/) {
    var tutorial = params[0];
    var state = params[1];

    var states = _tomatoTutorialsTutorials['default'][tutorial];

    return _lodashLodash['default'].indexOf(states, state);
  }

  exports['default'] = _ember['default'].Helper.helper(tutorialState);
});