define("tomato/templates/custom/round-number-column", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 85
          }
        },
        "moduleName": "tomato/templates/custom/round-number-column.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "text-right");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        return morphs;
      },
      statements: [["inline", "digit-group-format", [["subexpr", "get", [["get", "record", ["loc", [null, [1, 50], [1, 56]]]], ["get", "column.propertyName", ["loc", [null, [1, 57], [1, 76]]]]], [], ["loc", [null, [1, 45], [1, 77]]]]], [], ["loc", [null, [1, 24], [1, 79]]]]],
      locals: [],
      templates: []
    };
  })());
});