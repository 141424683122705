define('tomato/initializers/moment-internet-time', ['exports', 'moment'], function (exports, _moment) {
    exports.initialize = initialize;

    function initialize() /* application */{
        var url = "https://prazza-utils.herokuapp.com/unix";

        $.ajax({
            url: url,
            dataType: 'JSON',
            type: 'GET',
            async: false,
            crossDomain: true,
            success: function success(unix) {
                var offset = unix - (0, _moment['default'])().unix();
                _moment['default'].now = function () {
                    return +new Date() + offset * 1000;
                };
            },
            failure: function failure() {}
        });
    }

    exports['default'] = {
        name: 'moment-internet-time',
        initialize: initialize
    };
});