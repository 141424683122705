define('tomato/mixins/lang-actions', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Mixin.create({
		i18n: _ember['default'].inject.service(),
		moment: _ember['default'].inject.service(),

		actions: {
			changeLang: function changeLang(langCode) {
				this.set("i18n.locale", langCode);
				this.get('moment').changeLocale(langCode);
			}
		}
	});
});