define('tomato/models/user', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'tomato/utils/store-with-week', 'lodash/lodash'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _tomatoUtilsStoreWithWeek, _lodashLodash) {

    function calcSellerKPI(remainingTomatoes, money, avgTomatoPrice, fine, goalTomatoes) {
        if (remainingTomatoes < 0) {
            money = money + remainingTomatoes * (avgTomatoPrice + fine);
        }

        return money / goalTomatoes;
    }

    function calcBuyerKPI(money, tomatoes, goalTomatoes, retailPrice, remainingTomatoes, fine, fixedCost) {
        var costBuying = Math.abs(money);
        var totalRevenue = Math.min(tomatoes, goalTomatoes) * retailPrice;
        var totalFine = Math.max(0, remainingTomatoes * fine);
        var totalFixedCosts = goalTomatoes * fixedCost;

        return (totalRevenue - costBuying - totalFixedCosts - totalFine) / goalTomatoes;
        // body...
    }

    exports['default'] = _emberDataModel['default'].extend({
        i18n: _ember['default'].inject.service(),

        // normal attribtues
        name: (0, _emberDataAttr['default'])('string'),

        goalTomatoes: (0, _tomatoUtilsStoreWithWeek['default'])("userGame.weekCnt", "goalTomatoes"),
        tomatoes: (0, _tomatoUtilsStoreWithWeek['default'])("userGame.weekCnt", "tomatoes"),
        money: (0, _tomatoUtilsStoreWithWeek['default'])("userGame.weekCnt", "money"),
        extOfferTomato: (0, _tomatoUtilsStoreWithWeek['default'])("userGame.weekCnt", "extOfferTomato"),
        extOfferPrice: (0, _tomatoUtilsStoreWithWeek['default'])("userGame.weekCnt", "extOfferPrice"),

        enableExternalTrading: (0, _emberDataAttr['default'])('boolean', { 'default': false }),
        isSeller: (0, _emberDataAttr['default'])('boolean'),
        playerWeekStatus: (0, _emberDataAttr['default'])('json'),

        prognosis: (0, _emberDataAttr['default'])('number', { 'default': 0.0 }),

        // relational attributes
        userGame: (0, _emberDataRelationships.belongsTo)('game'),
        receivedOffers: (0, _emberDataRelationships.hasMany)('offer', { async: true, inverse: 'receiver' }),
        sentOffers: (0, _emberDataRelationships.hasMany)('offer', { async: true, inverse: 'sender' }),

        logPlayerStatus: _ember['default'].computed("sellerKPI", "buyerKPI", function () {
            var _this = this;

            var isSellerLUT = {
                'true': ["goalTomatoes", "remainingTomatoes", "tomatoes", "sellerKPI"],
                'false': ["goalTomatoes", "remainingTomatoes", "tomatoes", "buyerKPI"]
            };
            var propsToLog = isSellerLUT[this.get("isSeller")];
            var vals = propsToLog.map(function (x) {
                return x + ':' + _this.get(x);
            });
            return vals.join(", ");
        }),

        absoluteTomatoes: _ember['default'].computed("tomatoes", function () {
            return Math.abs(this.get("tomatoes"));
        }),

        remainingTomatoes: _ember['default'].computed("tomatoes", "goalTomatoes", function () {
            return this.get("goalTomatoes") - Math.abs(this.get("tomatoes"));
        }),

        avgTomatoPrice: _ember['default'].computed("tomatoes", "money", function () {
            if (this.get("money") === 0) {
                return 0;
            }
            return Math.abs(this.get("money") / this.get("tomatoes"));
        }),

        weeklyKPIOverview: _ember['default'].computed("sellerKPI", "buyerKPI", function () {
            var _this2 = this;

            var x = this.get("playerWeekStatus");
            delete x["firebaseDummyPlaceholder"]; // needs to be kicked out, otherwise causes trouble ;-)

            var xArr = _lodashLodash['default'].map(x, function (v, k) {
                return _lodashLodash['default'].extend({ idx: k }, v);
            });

            var fn = function fn() {};
            if (this.get("isSeller")) {
                fn = function (goalTomatoes, money, tomatoes) {
                    var remainingTomatoes = +_this2.get("remainingTomatoes");
                    var fine = +_this2.get("userGame.fine");
                    var avgTomatoPrice = +_this2.get('avgTomatoPrice');
                    return calcSellerKPI(remainingTomatoes, money, avgTomatoPrice, fine, goalTomatoes);
                };
            } else {
                fn = function (goalTomatoes, money, tomatoes) {
                    var retailPrice = +_this2.get("userGame.retailPrice");
                    var fixedCost = +_this2.get("userGame.fixedCost");
                    var remainingTomatoes = +_this2.get("remainingTomatoes");
                    var fine = +_this2.get("userGame.fine");
                    return calcBuyerKPI(money, tomatoes, goalTomatoes, retailPrice, remainingTomatoes, fine, fixedCost);
                };
            }

            xArr.forEach(function (x) {
                x.kpi = fn(+(x.goalTomatoes || 0), +(x.money || 0), +(x.tomatoes || 0));
            });

            return xArr;
            // goalTomatoes, money, tomatoes
        }),

        sellerKPI: _ember['default'].computed("tomatoes", "money", function () {
            var remainingTomatoes = +this.get("remainingTomatoes");
            var money = +this.get("money");
            var fine = +this.get("userGame.fine");
            var avgTomatoPrice = +this.get('avgTomatoPrice');
            var goalTomatoes = +this.get('goalTomatoes');

            return calcSellerKPI(remainingTomatoes, money, avgTomatoPrice, fine, goalTomatoes);
        }),

        buyerKPI: _ember['default'].computed("userGame.fine", "userGame.fixedCost", "userGame.retailPrice", "money", "goalTomatoes", function () {
            var retailPrice = this.get("userGame.retailPrice");
            var fixedCost = this.get("userGame.fixedCost");
            var tomatoes = this.get("tomatoes");
            var goalTomatoes = this.get("goalTomatoes");
            var remainingTomatoes = this.get("remainingTomatoes");
            var fine = this.get("userGame.fine");
            var money = this.get('money');

            return calcBuyerKPI(money, tomatoes, goalTomatoes, retailPrice, remainingTomatoes, fine, fixedCost);
        }),

        //Result s1, s2, b1, b2...
        playerIdInGame: _ember['default'].computed("playerPosition", function () {
            var prefix = this.get("isSeller") ? "s" : "b";
            var pos = this.get("playerPosition");
            return '' + prefix + pos;
        }),

        //Result Seller 1 - Bob
        descriptivePlayerIdInGame: _ember['default'].computed("playerPosition", "name", function () {
            var prefix = this.get("isSeller") ? "player.seller" : "player.buyer";
            prefix = this.get('i18n').t(prefix);
            var pos = this.get("playerPosition");
            var postfix = this.get("name") ? ' - ' + this.get("name") : '';
            return prefix + ' ' + pos + postfix;
        }),

        descriptivePlayerIdInGameForLogger: _ember['default'].computed("playerPosition", "name", function () {
            var prefix = this.get("isSeller") ? "seller" : "buyer";
            var pos = this.get("playerPosition");
            var postfix = this.get("name") ? ' - ' + this.get("name") : '';
            return prefix + ' ' + pos + postfix;
        }),

        playerPosition: _ember['default'].computed("userGame.buyers", "userGame.sellers", "isSeller", "id", function () {
            if (this.get("isSeller")) {
                // for the sellers
                return this.get("userGame.sellers").map(function (x) {
                    return x.get("id");
                }).indexOf(this.get("id")) + 1;
            } else {
                // for the buyers
                if (this.get("userGame.buyers")) {
                    return this.get("userGame.buyers").map(function (x) {
                        return x.get("id");
                    }).indexOf(this.get("id")) + 1;
                }
            }
        }),

        // groupedReceivedOpenOffers: Ember.computed("traders.@each.id", "receivedOffers.@each.state", "sentOpenOffers.@each.state", "historicOffers.@each.state", function () {
        groupedReceivedOpenOffers: _ember['default'].computed("traders.@each.id", "receivedOpenOffers", "sentOpenOffers", "historicOffers", function () {
            var userIds = this.get("traders").map(function (x) {
                return { "id": x.get("id"), "ref": x };
            }); // [1, 2, 3]
            var tmpReturnObj = _lodashLodash['default'].indexBy(userIds, "id"); // {1: 1, 2: 2, 3: 3}
            tmpReturnObj["External"] = { "id": "External", "ref": { 'name': "External" } };

            var receivedOpenOffersLUT = _lodashLodash['default'].groupBy(this.get("receivedOpenOffers"), function (x) {
                return x.get("senderId");
            });
            var sentOpenOffersLUT = _lodashLodash['default'].groupBy(this.get("sentOpenOffers"), function (x) {
                return x.get("receiverId");
            });
            var historicOffersSentLUT = _lodashLodash['default'].groupBy(this.get("historicOffers"), function (x) {
                return x.get("senderId");
            });
            var historicOffersReceivedLUT = _lodashLodash['default'].groupBy(this.get("historicOffers"), function (x) {
                return x.get("receiverId");
            });

            var newReturnObj = _lodashLodash['default'].mapValues(tmpReturnObj, function (v, k) {
                var a = historicOffersReceivedLUT[k] ? historicOffersReceivedLUT[k] : [];
                var b = historicOffersSentLUT[k] ? historicOffersSentLUT[k] : [];
                var allHistorcOffers = a.concat(b);
                allHistorcOffers = _lodashLodash['default'].sortBy(allHistorcOffers, function (o) {
                    return o.get("ts");
                });

                return {
                    user: v.ref,
                    openOffers: receivedOpenOffersLUT[k] ? receivedOpenOffersLUT[k] : [],
                    sentOffers: sentOpenOffersLUT[k] ? sentOpenOffersLUT[k] : [],
                    history: allHistorcOffers
                };
            });

            // console.log(newReturnObj);

            return newReturnObj;
        }),

        // computed attributes

        externalOffers: _ember['default'].computed("groupedReceivedOpenOffers", function () {
            return this.get("groupedReceivedOpenOffers.External");
        }),

        receivedOpenOffers: _ember['default'].computed.filter('receivedOffers.@each.state', function (el) {
            return el.get("state") === "open";
        }),
        sentOpenOffers: _ember['default'].computed.filter('sentOffers.@each.state', function (el) {
            return el.get("state") === "open";
        }),

        historicOffers: _ember['default'].computed('receivedOffers.@each.state', 'sentOffers.@each.state', function () {
            var receivedOffers = this.get('receivedOffers').filter(function (el) {
                return el.get("state") !== "open";
            });
            var sentOffers = this.get('sentOffers').filter(function (el) {
                return el.get("state") !== "open";
            });

            var combination = [].concat(receivedOffers, sentOffers);
            return _lodashLodash['default'].sortBy(combination, function (o) {
                return o.get('ts');
            }).reverse();
        }),

        roleDescription: _ember['default'].computed('isSeller', function () {
            return this.get('isSeller') ? 'seller' : 'buyer';
        }),

        traders: _ember['default'].computed('isSeller', 'userGame.sellers.[]', 'userGame.buyers.[]', function () {
            if (this.get('isSeller')) {
                return this.get('userGame.buyers');
            } else {
                return this.get('userGame.sellers');
            }
        })
    });
});