define('tomato/initializers/chart-animation', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() /* application */{
    Chart.defaults.global.hover.animationDuration = 0;
  }

  exports['default'] = {
    name: 'chart-animation',
    initialize: initialize
  };
});