define('tomato/helpers/num-modulo', ['exports', 'ember'], function (exports, _ember) {
  exports.numModulo = numModulo;

  function numModulo(params /*, hash*/) {
    var prm0 = params[0];
    var prm1 = params[1];
    return prm0 % prm1;
  }

  exports['default'] = _ember['default'].Helper.helper(numModulo);
});