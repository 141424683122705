define('tomato/controllers/historical', ['exports', 'ember', 'tomato/mixins/table-utilities', 'lodash/lodash', 'moment'], function (exports, _ember, _tomatoMixinsTableUtilities, _lodashLodash, _moment) {
  exports['default'] = _ember['default'].Controller.extend(_tomatoMixinsTableUtilities['default'], {
    needsReload: _ember['default'].computed("model.[]", function () {
      var history = this.get("model");
      var offerIds = history.map(function (x) {
        return x.get("offerId");
      });
      //console.log(sessionStorage["allObjIds"]);
      //console.log(JSON.stringify(_.uniq(offerIds).sort()));
      return sessionStorage["allObjIds"] !== JSON.stringify(_lodashLodash['default'].uniq(offerIds).sort());
    }),

    // Descending sort of historyLogs.
    historyLogsSortingDescById: ['ts:desc'],
    sortedHistoryLogsById: _ember['default'].computed.sort('model', 'historyLogsSortingDescById'),

    columns: _ember['default'].computed("i18n.locale", function () {
      return [{
        "propertyName": "round",
        "title": this.localize("history.round")
      }, {
        "propertyName": "idxOfOfferInGameCalc",
        "title": this.localize("history.calculatedOfferID")
      }, {
        "propertyName": "idxOfOfferInGame",
        "title": this.localize("history.offerID")
      }, {
        "propertyName": "userSender",
        "title": this.localize("history.sender")
      }, {
        "propertyName": "userReceiver",
        "title": this.localize("history.receiver")
      }, {
        "propertyName": "state",
        "title": this.localize("history.status")
      }, {
        "propertyName": "offer",
        "title": this.localize("history.offer")
      }, {
        "propertyName": "tomatoesOffer",
        "title": this.localize("history.tomatoes")
      }, {
        "propertyName": "priceOffer",
        "title": this.localize("history.unitPrice"),
        "template": "custom/euro-currency-column"
      }, {
        "propertyName": "ts",
        "title": this.localize("history.time"),
        "template": "custom/moment-time-column"
      }];
    }),

    actions: {
      importHistory: function importHistory(rows) {
        var _this = this;

        if (confirm(this.get("i18n").t("history.confirm"))) {
          (function () {
            var store = _this.get("store");
            var model = _this.get("model");
            var game = _this.get("game");

            model.forEach(function (history) {
              history.destroyRecord();
            });

            model = [];

            // offerID and cssStatus are not available, but are fortunately not needed for imported games.
            rows.forEach(function (row) {
              // offerTomatoes and offerPrice are not required to be backwards compatible with older exports.
              if (typeof row.userSender !== "undefined" && typeof row.userReceiver !== "undefined" && typeof row.state !== "undefined" && typeof row.offer !== "undefined" && typeof row.round !== "undefined" && typeof row.idxOfOfferInGameCalc !== "undefined" && typeof row.tsDesc !== "undefined") {
                // idxOfOfferInGame was originally something else, but using the imported idxOfOfferInGameCalc
                // should result in the same behaviour (this was the computed idxOfOfferInGameCalc originally).
                var _history = store.createRecord("history", {
                  userSender: row.userSender,
                  userReceiver: row.userReceiver,
                  state: row.state,
                  offer: row.offer,
                  round: row.round,
                  offerTomotoes: row.offerTomatoes,
                  offerPrice: row.offerPrice,
                  idxOfOfferInGame: row.idxOfOfferInGameCalc,
                  ts: +(0, _moment['default'])(row.tsDesc, "HH:mm:ss"),
                  historyGame: game
                });

                _history.save();

                model.pushObject(_history);
              }
            });

            _this.set("model", model);

            // Replace the session storage originally set up in the route as well.
            var offerIds = model.map(function (history) {
              return history.get("offerId");
            });
            sessionStorage["allObjIds"] = JSON.stringify(_lodashLodash['default'].uniq(offerIds).sort());

            game.set("isImported", true);
            game.save();
          })();
        }
      },

      exportHistory: function exportHistory() {
        var data = [];
        var titles = ["round", "idxOfOfferInGameCalc", "userSender", "userReceiver", "state", "offer", "tomatoesOffer", "priceOffer", "tsDesc"];

        data.push(titles);

        this.get('sortedHistoryLogsById').map(function (historyElement) {
          // historyElement => 1x historical element
          // now go through each element in titles and get it from historyElement
          var resolvedTitles = titles.map(function (titleElement) {
            return historyElement.get(titleElement);
          });
          data.push(resolvedTitles);
        });

        this.get('excel')['export'](data, 'sheet1', 'History.xlsx');
      }
    }
  });
});