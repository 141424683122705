define("tomato/mixins/tutorial-actions", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    tutorialState: _ember["default"].inject.service(),

    actions: {
      changeTutorialState: function changeTutorialState(tutorial, state) {
        this.get("tutorialState").setTutorialState(tutorial, state);
      },

      recedeTutorialState: function recedeTutorialState(tutorial) {
        this.get("tutorialState").decrementTutorialState(tutorial);
      },

      advanceTutorialState: function advanceTutorialState(tutorial) {
        this.get("tutorialState").incrementTutorialState(tutorial);
      }
    }
  });
});