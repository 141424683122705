define('tomato/components/player-results-player-kpis', ['exports', 'ember', 'tomato/mixins/chart-utilities', 'lodash/lodash'], function (exports, _ember, _tomatoMixinsChartUtilities, _lodashLodash) {

  function getDataForWeeksForPosition(weeks, position, kpiPerPlayerPerWeek) {
    return weeks.map(function (week) {
      return kpiPerPlayerPerWeek[week][position];
    });
  }

  function getColorForPosition(position, playerPosition) {
    var hue = 9 + 200 * (position - 1);
    var saturation = 100;
    var lightness = 64;

    if (position === playerPosition) {
      // The player is always tomato red.
      hue = 9;
    } else if (position === 1) {
      // The competitor who would have been tomato red is the color the player would have been.
      hue = 9 + 200 * (playerPosition - 1);
    }

    return 'hsl(' + hue + ',' + saturation + '%,' + lightness + '%)';
  }

  function getBordersForPosition(position, playerPosition) {
    var hue = 9 + 200 * (position - 1);
    var saturation = 100;
    var lightness = 44;

    if (position === playerPosition) {
      // The player is always tomato red.
      hue = 9;
    } else if (position === 1) {
      // The competitor who would have been tomato red is the color the player would have been.
      hue = 9 + 200 * (playerPosition - 1);
    }

    return 'hsl(' + hue + ',' + saturation + '%,' + lightness + '%)';
  }

  exports['default'] = _ember['default'].Component.extend(_tomatoMixinsChartUtilities['default'], {
    i18n: _ember['default'].inject.service(),

    minimumKPI: -1.0,
    maximumKPI: 1.0,

    quantumState: false,

    calculateKPIBounds: _ember['default'].on("init", _ember['default'].observer("player", "buyers", "sellers", "weeks", "positionsForPlayers", "kpiPerPlayerPerWeek", function () {
      var player = this.get("player");

      var buyers = this.get("buyers");
      var sellers = this.get("sellers");

      var weeks = this.get("weeks");

      var positionsForPlayers = this.get("positionsForPlayers");
      var kpiPerPlayerPerWeek = this.get("kpiPerPlayerPerWeek");

      var playerPosition = player.get("playerPosition");
      var playerIsSeller = player.get("isSeller");

      var competitors = playerIsSeller ? sellers : buyers;

      var minimumKPI = -1.0;
      var maximumKPI = 1.0;

      var minimum = Number.MAX_VALUE;
      var maximum = -Number.MAX_VALUE;

      competitors.forEach(function (competitor) {
        var competitorRole = competitor.get("roleDescription");
        var competitorPosition = competitor.get("playerPosition");

        var position = positionsForPlayers[competitorRole][competitorPosition];

        var competitorMinimum = weeks.reduce(function (minimum, week) {
          var kpi = kpiPerPlayerPerWeek[week][position];

          if (kpi < minimum) {
            return kpi;
          } else {
            return minimum;
          }
        }, Number.MAX_VALUE);

        var competitorMaximum = weeks.reduce(function (maximum, week) {
          var kpi = kpiPerPlayerPerWeek[week][position];

          if (kpi > maximum) {
            return kpi;
          } else {
            return maximum;
          }
        }, -Number.MAX_VALUE);

        if (competitorMinimum < minimum) {
          minimum = competitorMinimum;
        }

        if (competitorMaximum > maximum) {
          maximum = competitorMaximum;
        }
      });

      minimumKPI = _lodashLodash['default'].min([0.0, minimum]);
      maximumKPI = _lodashLodash['default'].max([0.0, maximum]);

      this.set("minimumKPI", minimumKPI);
      this.set("maximumKPI", maximumKPI);

      // Force the chart to update.
      this.set("quantumState", !this.get("quantumState"));
    })),

    data: _ember['default'].computed("i18n.locale", "player", "buyers", "sellers", "weeks", "positionsForPlayers", "kpiPerPlayerPerWeek", function () {
      var i18n = this.get("i18n");

      var player = this.get("player");

      var buyers = this.get("buyers");
      var sellers = this.get("sellers");

      var weeks = this.get("weeks");

      var positionsForPlayers = this.get("positionsForPlayers");
      var kpiPerPlayerPerWeek = this.get("kpiPerPlayerPerWeek");

      var playerPosition = player.get("playerPosition");
      var playerIsSeller = player.get("isSeller");

      var competitors = playerIsSeller ? sellers : buyers;

      var dataSets = competitors.map(function (competitor) {
        var competitorRole = competitor.get("roleDescription");
        var competitorPosition = competitor.get("playerPosition");

        var labels = [i18n.t("player.results.player.kpis." + competitorRole), competitorPosition];

        var label = labels.join(i18n.t("player.results.player.kpis.space"));

        var position = positionsForPlayers[competitorRole][competitorPosition];

        var data = getDataForWeeksForPosition(weeks, position, kpiPerPlayerPerWeek);
        var colors = getColorForPosition(competitorPosition, playerPosition);
        var borders = getBordersForPosition(competitorPosition, playerPosition);

        return this.createBarChartDataSet(label, data, colors, borders);
      }, this);

      var labels = weeks.map(function (week) {
        return i18n.t("player.results.player.kpis.week") + i18n.t("player.results.player.kpis.space") + week;
      });

      return this.createChartData(dataSets, labels);
    }),

    options: _ember['default'].computed("i18n.locale", "minimumKPI", "maximumKPI", function () {
      var i18n = this.get("i18n");

      var minimumKPI = this.get("minimumKPI");
      var maximumKPI = this.get("maximumKPI");

      return {
        // These options attempt to force negative barcharts to include the zero line.
        scales: {
          yAxes: [{
            ticks: {
              min: minimumKPI * 1.1,
              max: maximumKPI * 1.1
            }
          }]
        },
        // These options format the tool tip value to something readable.
        tooltips: {
          callbacks: {
            label: function label(toolTipItem, data) {
              var label = data.datasets[toolTipItem.datasetIndex].label;
              var value = toolTipItem.yLabel;

              // Graciously stolen from the digit-format helper.
              value = parseFloat(Math.round(value * 100) / 100).toFixed(2);

              return label + i18n.t("chart.toolTipSeparator") + value;
            }
          }
        }
      };
    })
  });
});