define("tomato/components/player-results-all-transactions", ["exports", "ember", "tomato/mixins/offer-utilities", "tomato/mixins/table-utilities"], function (exports, _ember, _tomatoMixinsOfferUtilities, _tomatoMixinsTableUtilities) {
  exports["default"] = _ember["default"].Component.extend(_tomatoMixinsOfferUtilities["default"], _tomatoMixinsTableUtilities["default"], {
    transactions: _ember["default"].computed("i18n.locale", "offers", function () {
      return this.get("offers").map(function (offer) {
        var roundParameters = offer.get("round").split(/ /);
        var senderParameters = offer.get("userSender").split(/[ -]+/);
        var receiverParameters = offer.get("userReceiver").split(/[ -]+/);
        var offerParameters = offer.get("offer").split(/:|, /);

        var role = senderParameters[0];
        var position = senderParameters[1];
        var name = senderParameters[2];

        var sender = this.localize("player.results.transactions.all." + role) + " " + position + (name ? " " + name : "");
        var receiver = this.localize("player.results.transactions.all." + role) + " " + position + (name ? " " + name : "");

        if (this.isOfferExternalUser(senderParameters[0])) {
          sender = this.localize("player.results.transactions.all.external");
        }

        if (this.isOfferExternalUser(receiverParameters[0])) {
          receiver = this.localize("player.results.transactions.all.external");
        }

        return {
          round: parseInt(roundParameters[1]),
          sender: sender,
          receiver: receiver,
          amount: parseInt(offerParameters[1]),
          unitPrice: parseFloat(offerParameters[3])
        };
      }, this);
    }),

    columns: _ember["default"].computed("i18n.locale", function () {
      return [{
        "propertyName": "round",
        "title": this.localize("player.results.transactions.round"),
        "template": "custom/round-number-column"
      }, {
        "propertyName": "sender",
        "title": this.localize("player.results.transactions.sender")
      }, {
        "propertyName": "receiver",
        "title": this.localize("player.results.transactions.receiver")
      }, {
        "propertyName": "amount",
        "title": this.localize("player.results.transactions.amount"),
        "template": "custom/tomato-amount-column"
      }, {
        "propertyName": "unitPrice",
        "title": this.localize("player.results.transactions.unitPrice"),
        "template": "custom/euro-currency-column"
      }];
    })
  });
});