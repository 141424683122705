define("tomato/mixins/offer-utilities", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    isOfferOpenState: function isOfferOpenState(state) {
      return state === "open";
    },

    isOfferAcceptedState: function isOfferAcceptedState(state) {
      return state === "accepted";
    },

    isOfferDeclinedState: function isOfferDeclinedState(state) {
      return state === "declined";
    },

    isOfferConfirmedState: function isOfferConfirmedState(state) {
      return state === "confirmed";
    },

    isOfferRecalledOpenState: function isOfferRecalledOpenState(state) {
      return state === "recalled - open";
    },

    isOfferRecalledConfirmedState: function isOfferRecalledConfirmedState(state) {
      return state === "recalled - confirmed";
    },

    isOfferState: function isOfferState(state) {
      return this.isOfferOpenState(state) || this.isOfferAcceptedState(state) || this.isOfferDeclinedState(state) || this.isOfferConfirmedState(state) || this.isOfferRecalledOpenState(state) || this.isOfferRecalledConfirmedState(state);
    },

    isOfferUser: function isOfferUser(user, role, position) {
      if (typeof position === "undefined") {
        return user.startsWith(role);
      } else {
        return user.startsWith(role + " " + position);
      }
    },

    isOfferBuyerUser: function isOfferBuyerUser(user, position) {
      return this.isOfferUser(user, "buyer");
    },

    isOfferSellerUser: function isOfferSellerUser(user, position) {
      return this.isOfferUser(user, "seller");
    },

    isOfferExternalUser: function isOfferExternalUser(user) {
      return user === "External";
    }
  });
});