define("tomato/components/player-results-player-transactions", ["exports", "ember", "tomato/mixins/offer-utilities", "tomato/mixins/chart-utilities", "tomato/mixins/game-config-parser", "lodash/lodash", "moment"], function (exports, _ember, _tomatoMixinsOfferUtilities, _tomatoMixinsChartUtilities, _tomatoMixinsGameConfigParser, _lodashLodash, _moment) {

  function getDataForOffers(offers) {
    return offers.map(function (offer) {
      var offerParameters = offer.get("offer").split(/, |:/);

      var unitPrice = offerParameters[3];

      var time = offer.get("ts");

      return {
        x: (0, _moment["default"])(time),
        y: +unitPrice
      };
    });
  }

  function getColorsForOffers(offers) {
    return offers.map(function (offer) {
      var state = offer.get("state");

      // See also the ".legend-*" classes in "app.css".
      var stateColors = {
        "open": "hsl(9,0%,100%)",
        "confirmed": "hsl(69,100%,64%)",
        "recalled - confirmed": "hsl(9,0%,64%)",
        "accepted": "hsl(129,100%,64%)",
        "declined": "hsl(9,100%,64%)",
        "recalled - open": "hsl(9,0%,64%)"
      };

      return stateColors[state];
    });
  }

  function getBordersForOffers(offers, typesForRounds) {
    return offers.map(function (offer) {
      var round = parseInt(offer.get("round").split(/ /)[1]);

      var type = typesForRounds[round];

      // See also the ".legend-*" classes in "app.css".
      var typeBorders = {
        "weekly": "hsl(9,100%,44%)",
        "daily": "hsl(129,100%,24%)"
      };

      return typeBorders[type];
    });
  }

  function getRadiiForOffers(offers) {
    return offers.map(function (offer) {
      var offerParameters = offer.get("offer").split(/, |:/);

      var tomatoes = parseInt(offerParameters[1]);

      // Apply an exponential bracketing.
      if (tomatoes <= 50000) {
        return 4;
      } else if (tomatoes <= 250000) {
        return 6;
      } else if (tomatoes <= 1250000) {
        return 8;
      } else {
        return 10;
      }
    });
  }

  function getColorForPosition(position) {
    var hue = 9 + 200 * (position - 1);
    var saturation = 100;
    var lightness = 64;

    return "hsl(" + hue + "," + saturation + "%," + lightness + "%)";
  }

  function getDashForDirection(direction) {
    if (direction === "send") {
      // A solid line for outgoing offers.
      return [];
    } else if (direction === "receive") {
      // A dashed line for incoming offers.
      return [4, 2];
    }
  }

  exports["default"] = _ember["default"].Component.extend(_tomatoMixinsOfferUtilities["default"], _tomatoMixinsChartUtilities["default"], {
    i18n: _ember["default"].inject.service(),

    selectedWeek: 1,

    offers: _ember["default"].computed("histories.[]", "histories.@each", function () {
      return this.get("histories").filter(function (history) {
        var state = history.get("state");

        return this.isOfferState(state);
      }, this);
    }),

    indices: _ember["default"].computed("offers", function () {
      var offers = this.get("offers");

      var indices = offers.map(function (offer) {
        return parseInt(offer.get("idxOfOfferInGame"));
      });

      return _lodashLodash["default"].uniq(indices).sort(function (value1, value2) {
        return value1 - value2;
      });
    }),

    groups: _ember["default"].computed("offers", "indices", function () {
      var offers = this.get("offers");
      var indices = this.get("indices");

      var groups = [];

      indices.forEach(function (index) {
        var group = offers.filter(function (offer) {
          var offerIndex = parseInt(offer.get("idxOfOfferInGame"));

          return offerIndex === index;
        });

        // No need to sort the offers, they should already be in chronological order.
        groups.pushObject(group);
      });

      // No need to sort the groups, they should already be in index order.
      return groups;
    }),

    selection: _ember["default"].computed("groups", "weeksForRounds", "selectedWeek", function () {
      var groups = this.get("groups");

      var weeksForRounds = this.get("weeksForRounds");
      var selectedWeek = this.get("selectedWeek");

      return groups.filter(function (group) {
        // We know there is at least one offer, and that they all contain (almost) the same information.
        var offer = group[0];

        var round = parseInt(offer.get("round").split(/ /)[1]);

        var week = weeksForRounds[round];

        return week === selectedWeek;
      });
    }),

    data: _ember["default"].computed("i18n.locale", "player", "buyers", "sellers", "selection", "typesForRounds", function () {
      var i18n = this.get("i18n");

      var player = this.get("player");

      var buyers = this.get("buyers");
      var sellers = this.get("sellers");

      var selection = this.get("selection");

      var playerRole = player.get("roleDescription");
      var playerPosition = player.get("playerPosition");
      var playerIsSeller = player.get("isSeller");

      var clients = playerIsSeller ? buyers : sellers;

      var actions = ["send", "receive"];

      var dataSets = [];

      clients.forEach(function (client) {
        var clientRole = client.get("roleDescription");
        var clientPosition = client.get("playerPosition");

        actions.forEach(function (action) {
          var isSender = action === "send";

          var senderRole = isSender ? playerRole : clientRole;
          var senderPosition = isSender ? playerPosition : clientPosition;
          var receiverRole = isSender ? clientRole : playerRole;
          var receiverPosition = isSender ? clientPosition : playerPosition;

          var groups = selection.filter(function (group) {
            // We know there is at least one offer, and that they all contain (almost) the same information.
            var offer = group[0];

            var sender = offer.get("userSender");
            var receiver = offer.get("userReceiver");

            return this.isOfferUser(sender, senderRole, senderPosition) && this.isOfferUser(receiver, receiverRole, receiverPosition);
          }, this);

          var offers = groups.reduce(function (array, group) {
            return array.concat(group);
          }, []);

          var key = isSender ? "player.results.player.transactions.to" : "player.results.player.transactions.from";

          var labels = [i18n.t(key), i18n.t("player.results.player.transactions." + clientRole), clientPosition];

          var label = labels.join(i18n.t("player.results.player.transactions.space"));

          var data = getDataForOffers(offers);
          var colors = getColorsForOffers(offers);
          var borders = getBordersForOffers(offers, this.get("typesForRounds"));
          var radii = getRadiiForOffers(offers);
          var color = getColorForPosition(clientPosition);
          var dash = getDashForDirection(action);

          var dataSet = this.createLineChartDataSet(label, data, colors, borders, radii, color, dash);

          dataSets.pushObject(dataSet);
        }, this);
      }, this);

      return this.createChartData(dataSets);
    }),

    options: {
      scales: {
        xAxes: [{
          type: 'time',
          position: 'bottom',
          time: {
            displayFormats: {
              'second': "HH:mm:ss"
            },
            tooltipFormat: "DD-MM-YYYY HH:mm:ss",
            unit: 'second',
            unitStepSize: 30
          }
        }]
      }
    },

    actions: {
      selectWeek: function selectWeek(week) {
        this.set("selectedWeek", week);
      }
    }
  });
});