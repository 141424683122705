define('tomato/transforms/json', ['exports', 'ember-data/transform', 'lodash/lodash'], function (exports, _emberDataTransform, _lodashLodash) {
  exports['default'] = _emberDataTransform['default'].extend({
    deserialize: function deserialize(serialized) {
      return serialized;
      // return JSON.parse(serialized) | {};
    },

    serialize: function serialize(deserialized) {
      if (_lodashLodash['default'].isEmpty(deserialized)) {
        return { firebaseDummyPlaceholder: "firebaseDummyPlaceholder" };
      }{
        return deserialized;
      }

      // return JSON.stringify(deserialized);
    }
  });
});