define('tomato/controllers/games', ['exports', 'ember', 'tomato/mixins/offer-actions', 'tomato/mixins/lang-actions', 'tomato/mixins/log-functions', 'lodash/lodash', 'moment'], function (exports, _ember, _tomatoMixinsOfferActions, _tomatoMixinsLangActions, _tomatoMixinsLogFunctions, _lodashLodash, _moment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Controller.extend(_tomatoMixinsOfferActions['default'], _tomatoMixinsLangActions['default'], _tomatoMixinsLogFunctions['default'], {
    autoTradingString: "",
    autoTradingArr: [],
    activeUser: null,
    gameConfiguration: null,
    game: _ember['default'].computed.alias("model"),

    isEditing: true,

    historyCompletelyLoaded: _ember['default'].observer("model.historyLogs.[]", "model.historyLogs.@each.isLoaded", function () {
      this.set("showFilterTable", false);
    }),

    autoTradingTimer: _ember['default'].on("init", function () {
      var _this = this;

      var inRangeCheck = function inRangeCheck(x, arr) {
        return _lodashLodash['default'].inRange(x, arr[0], arr[1]);
      };

      var isInTimeArray = function isInTimeArray(ts) {
        var autoTradingArr = _this.get('autoTradingArr');
        return autoTradingArr.some(function (arr) {
          return inRangeCheck(ts, arr);
        });
      };

      var timerHandle = setInterval(function () {
        var prevTimeMissingSec = _this.get("timeMissingSec");
        var newTimeMissingSec = (0, _moment['default'])(_this.get("game.timeEndTs")).diff((0, _moment['default'])());

        _this.set("timeMissingSec", newTimeMissingSec, 'seconds');

        var prevCheck = isInTimeArray(prevTimeMissingSec);
        var newCheck = isInTimeArray(newTimeMissingSec);

        // here we dont need to do anything
        if (prevCheck === newCheck) {
          return;
        }

        // if the new value is negative -> prev was positive -> flank down -> disable everything
        // must be the other case ;-) -> flank up
        var flankDown = newCheck === false;

        _this.get("game.users").forEach(function (u) {
          u.set("enableExternalTrading", !flankDown);
          u.save();

          var newHistoryObj = _this.get("store").createRecord('history', {
            offerId: undefined,
            userSender: u.get("descriptivePlayerIdInGameForLogger"),
            userReceiver: "",
            state: "Automatic change to external trade status",
            cssStatus: "",
            offer: u.get("enableExternalTrading") ? "Enabled" : "Disabled",
            round: "Round " + (_this.get("game.roundCnt") + 1),
            historyGame: _this.get("game")
          });

          newHistoryObj.save().then(function () {
            return true;
          });
        });
      }, 1000);
    }),

    actions: {
      setAutoTrading: function setAutoTrading(s) {
        var arr = s.split(";").map(function (x) {
          return x.split("-");
        });
        var autoTradingArr = arr.map(function (x) {
          return x.map(function (y) {
            return parseInt(+y * 60 * 1000);
          }).sort(function (a, b) {
            return a - b;
          });
        });
        this.set("autoTradingArr", autoTradingArr);

        this.set("autoTradingTs", (0, _moment['default'])().format("LTS"));
      },

      allowGlobalExternalTrading: function allowGlobalExternalTrading() {
        var _this2 = this;

        this.get("game.users").forEach(function (u) {
          u.toggleProperty("enableExternalTrading");
          u.save();

          var newHistoryObj = _this2.get("store").createRecord('history', {
            offerId: undefined,
            userSender: u.get("descriptivePlayerIdInGameForLogger"),
            userReceiver: "",
            state: "Global change to external trade status",
            cssStatus: "",
            offer: u.get("enableExternalTrading") ? "Enabled" : "Disabled",
            round: "Round " + (_this2.get("game.roundCnt") + 1),
            historyGame: _this2.get("game")
          });

          newHistoryObj.save().then(function () {
            return true;
          });
        });
      },

      // USER

      saveUser: function saveUser(user) {
        user.save();
      },

      deleteUser: function deleteUser(user) {
        user.destroyRecord();
      },

      rollbackUser: function rollbackUser(user) {
        user.rollbackAttributes();
      },

      setActiveUser: function setActiveUser(user) {
        this.set("activeUser", user);
      },

      addUser: function addUser(game, userType) {
        var isSeller = false;

        if (userType === 'seller') {
          isSeller = true;
        }

        var newUser = this.store.createRecord('user', { isSeller: isSeller });

        game.get('users').pushObject(newUser);

        newUser.save().then(function () {
          return game.save();
        });
      },

      // GAME

      pauseGame: function pauseGame(game) {
        game.set("timePausedTs", (0, _moment['default'])());
        game.save();
      },

      resumeGame: function resumeGame(game) {
        try {
          var pausedStartDelta = game.get("timePausedTs") - game.get("timeStartTs");
          var previousDelta = game.get("timeEndTs") - game.get("timeStartTs");
          game.set("timeStartTs", (0, _moment['default'])() - pausedStartDelta);
          game.set("timeEndTs", game.get("timeStartTs") + previousDelta);
        } catch (error) {
          console.log("Error when resuming...");
        }
        game.set("timePausedTs", undefined);
        game.save();
      },

      letGameEndInXMinutes: function letGameEndInXMinutes(game, xMinutes, shouldResetStart) {
        game.set("timeEndTs", (0, _moment['default'])() + xMinutes * 60 * 1000);

        if (shouldResetStart) {
          game.set("timeStartTs", (0, _moment['default'])());
        }

        game.save();
      },

      letGameEnd: function letGameEnd(game) {
        game.set("roundCnt", game.get("numberOfRounds.total"));

        this.send("nextRound", game);
      },

      nextRound: function nextRound(game, minutesPerRound) {
        var _this3 = this;

        var gameIsLastRound = this.get('game.isLastRound');

        minutesPerRound = game.getMinutesPerRoundForRound(1 + game.get("roundCnt"));

        if (!minutesPerRound) {
          minutesPerRound = 5;
        }

        var newHistoryObj = undefined;

        // Create history record to record moving to the next round
        if (game.get("roundCnt") !== 0) {
          newHistoryObj = this.store.createRecord('history', {
            offerId: undefined,
            userSender: "Round " + (game.get("roundCnt") + 1),
            userReceiver: "Round " + (game.get("roundCnt") + 2),
            state: "New Round",
            cssStatus: "info",
            offer: "Minutes on this round " + minutesPerRound, // We could also include tomato goal for each user
            round: "Round " + (game.get("roundCnt") + 1),
            historyGame: game
          });

          game.get("users").map(function (u) {
            var userHistory = _this3.store.createRecord('history', {
              offerId: undefined,
              userSender: 'Stats for ' + u.get("descriptivePlayerIdInGame"),
              userReceiver: "",
              state: "",
              cssStatus: "info",
              offer: u.get("logPlayerStatus"),
              round: "Round " + game.get("roundCnt"),
              historyGame: game
            });

            userHistory.save();

            if (gameIsLastRound) {
              var allKpis = u.get("weeklyKPIOverview").map(function (x) {
                return x.kpi;
              });
              var meanKpi = _lodashLodash['default'].sum(allKpis) / allKpis.length;

              var userTotalHistory = _this3.store.createRecord('history', {
                offerId: undefined,
                userSender: 'Final stats for ' + u.get("descriptivePlayerIdInGame"),
                userReceiver: "",
                state: "",
                cssStatus: "info",
                offer: 'Final KPI (average) ' + meanKpi,
                round: "Round " + game.get("roundCnt"),
                historyGame: game
              });

              userTotalHistory.save();
            }

            if (u.get("enableExternalTrading")) {
              u.set("enableExternalTrading", false);

              var _newHistoryObj = _this3.get("store").createRecord('history', {
                offerId: undefined,
                userSender: u.get("descriptivePlayerIdInGameForLogger"),
                userReceiver: "",
                state: "End of round change to external trade status",
                cssStatus: "",
                offer: u.get("enableExternalTrading") ? "Enabled" : "Disabled",
                round: "Round " + (_this3.get("game.roundCnt") + 1),
                historyGame: _this3.get("game")
              });

              _newHistoryObj.save().then(function () {
                return true;
              });
            }
          });
        } else {
          newHistoryObj = this.store.createRecord('history', {
            offerId: undefined,
            userSender: "Game starting",
            userReceiver: "Setting up:",
            state: "First Round",
            cssStatus: "info",
            offer: "Minutes on this round " + minutesPerRound, // We could also include tomato goal for each user
            round: "Round " + game.get("roundCnt"),
            historyGame: game
          });
        }

        newHistoryObj.save().then(function () {
          return true;
        });

        game.set("timeStartTs", (0, _moment['default'])());
        game.set("timeEndTs", (0, _moment['default'])() + minutesPerRound * 60 * 1000);
        game.incrementProperty("roundCnt", 1);

        game.get("offers").map(function (x) {
          return x.destroyRecord();
        });

        game.save();

        if (game.get("gameIsRunning")) {
          game.save().then(function () {
            var lut = [["getRetailpriceForRound", "retailPrice"], ["getMinutesPerRoundForRound", "minutesPerRound"], ["getSellerFineForRound", "fine"], ["getSellerFixedCostForRound", "fixedCost"]];

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = lut[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var _step$value = _slicedToArray(_step.value, 2);

                var fnName = _step$value[0];
                var attrName = _step$value[1];

                //console.log(fnName);
                //console.log(attrName);
                var newVal = game[fnName](game.get("roundCnt"));
                game.set(attrName, newVal);
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator['return']) {
                  _iterator['return']();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            game.save();
          });

          var autoTradingString = game.getTradingScheduleForRound(game.get("roundCnt"));
          var arr = autoTradingString.split(";").map(function (x) {
            return x.split("-");
          });
          var autoTradingArr = arr.map(function (x) {
            return x.map(function (y) {
              return parseInt(+y * 60 * 1000);
            }).sort(function (a, b) {
              return a - b;
            });
          });
          this.set("autoTradingString", autoTradingString);
          this.set("autoTradingArr", autoTradingArr);
          this.set("autoTradingTs", (0, _moment['default'])().format("LTS"));

          // Automatically perform the prognosis adjustment.
          if (game.get("currentGameSettings").tradeType === "daily") {
            game.get("allUsers").forEach(function (user) {
              user.set("goalTomatoes", game.getValueforUserCurrentRound(user.get("playerIdInGame")));
              user.set("extOfferTomato", game.getValueforUserCurrentRound(user.get("playerIdInGame"), '_extTomato'));
              user.set("extOfferPrice", game.getValueforUserCurrentRound(user.get("playerIdInGame"), '_extPrice'));

              var index = _lodashLodash['default'].random(5);

              var percentages = [-10, -5, 0, 0, 5, 10];
              var percentage = percentages[index];

              // Straight up copied from player-ov.js.
              var currentTomatoes = user.get("goalTomatoes");
              var newGoalTomatoes = Math.floor(currentTomatoes * (1.0 + 0.01 * percentage));

              user.set("goalTomatoes", newGoalTomatoes);
              user.set("prognosis", percentage);
              user.save();

              var anotherNewHistoryObj = _this3.store.createRecord('history', {
                offerId: undefined,
                userSender: "Prognosis modification to " + user.get("roleDescription") + " " + user.get("playerPosition"),
                userReceiver: "",
                state: "",
                cssStatus: "info",
                offer: "by " + percentage + "%",
                round: "Round " + game.get("roundCnt"),
                historyGame: game
              });

              anotherNewHistoryObj.save().then(function () {
                return true;
              });
            });
          } else {
            game.get("allUsers").forEach(function (u) {
              u.set("goalTomatoes", game.getValueforUserCurrentRound(u.get("playerIdInGame")));
              u.set("extOfferTomato", game.getValueforUserCurrentRound(u.get("playerIdInGame"), '_extTomato'));
              u.set("extOfferPrice", game.getValueforUserCurrentRound(u.get("playerIdInGame"), '_extPrice'));
              u.set("prognosis", 0);
              u.save();
            });
          }
        } else {
          this.set("autoTradingString", "");
          this.set("autoTradingArr", []);
          this.set("autoTradingTs", undefined);

          game.get("allUsers").forEach(function (u) {
            u.set("goalTomatoes", 0);
            u.set("extOfferTomato", 0);
            u.set("extOfferPrice", 0);
            u.set("prognosis", 0);
            u.save();
          });
        }
      },

      saveSettings: function saveSettings(game) {
        game.save();
        game.get("allUsers").forEach(function (u) {
          u.save();
        });
        this.toggleProperty("isEditing");
      },

      revertGameConfig: function revertGameConfig() {
        this.set("game.gameConfigurationRO", this.get("game.gameConfiguration"));
      },

      updateGameConfig: function updateGameConfig() {
        this.set("game.gameConfigurationSafe", this.get("game.gameConfigurationRO"));
        this.get("game").save();
      },

      saveGameConfig: function saveGameConfig() {
        var self = this;

        if (!self.get("game.isNew")) {
          var r = confirm(this.get("i18n").t("games.confirmation"));
          if (!r) {
            return;
          }
        }

        self.set("game.isNew", false);

        var historyQuery = this.get('store').query('history', {
          orderBy: "historyGame",
          equalTo: this.get("game.id")
        });

        historyQuery.then(function (records) {
          var historyPromises = records.map(function (record) {
            return record.destroyRecord();
          });

          var userPromises = self.get("game.users").filter(function (x) {
            return x && !x.get("isDeleted");
          }).map(function (x) {
            return x.destroyRecord();
          });

          self.get("game.users").clear();

          var promises = historyPromises.concat(userPromises);

          _ember['default'].RSVP.Promise.all(promises).then(function () {
            self.set("game.gameConfigurationSafe", self.get("game.gameConfigurationRO"));
            self.set("game.roundCnt", 0);
            self.get("game").save().then(function () {
              _lodashLodash['default'].range(self.get("game.numberOfPlayers.nrOfBuyers")).forEach(function () {
                self.send("addUser", self.get("game"), "buyer");
              });

              _lodashLodash['default'].range(self.get("game.numberOfPlayers.nrOfSellers")).forEach(function () {
                self.send("addUser", self.get("game"), "seller");
              });

              self.set("isConfiguration", false);

              var newHistoryObj = self.get("store").createRecord('history', {
                offerId: undefined,
                userSender: "---",
                userReceiver: "---",
                state: "New Config loaded",
                cssStatus: "info",
                offer: self.get("game.gameConfigurationSafe"),
                round: "---",
                historyGame: self.get("game")
              });
              newHistoryObj.save();
            });
          });
        });
      },

      testManyOffers: function testManyOffers() {
        var _this4 = this;

        _ember['default'].run(function () {
          var self = _this4;
          var game = _this4.get("game");
          var users = _this4.get("game.users").then(function (_users) {
            //sendOffer(game, sender, receiver, tomatoes, price)
            var users = _users.map(function (x) {
              return x;
            });

            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = _lodashLodash['default'].range(50)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var x = _step2.value;

                self.send("sendOffer", game, users[0], users[1], 1.0, 1.0);
                console.log(x);
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2['return']) {
                  _iterator2['return']();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }
          });
        });
      }
    }
  });
});