define('tomato/mixins/offer-actions', ['exports', 'ember', 'tomato/mixins/log-functions', 'moment'], function (exports, _ember, _tomatoMixinsLogFunctions, _moment) {
  exports['default'] = _ember['default'].Mixin.create(_tomatoMixinsLogFunctions['default'], {
    actions: {
      // OFFERS
      sendOfferAll: function sendOfferAll(game, sender) {
        var _this = this;

        sender.get("traders").map(function (el) {
          _this.send("sendOffer", game, sender, el);
        });
      },

      sendOffer: function sendOffer(game, sender, receiver, tomatoes, price) {
        var _this2 = this;

        _ember['default'].run(function () {
          var isExternal = false;
          if (+sender === 0 || +receiver === 0) {
            isExternal = true;
          }

          var newOffer = _this2.store.createRecord('offer', {
            tomatoes: tomatoes,
            price: price,
            idxOfOfferInGame: game.get("offerCnt"),
            isExternal: isExternal,
            roundNumber: game.get("roundNumber"),
            weekNumber: game.get("weekNumber"),
            ts: (0, _moment['default'])(),
            state: "open",
            notes: (0, _moment['default'])().format() + ';created\n'
          });

          _this2.logPlayerOffer(_this2.store, game, sender, receiver, tomatoes, price, "open", newOffer.get("id"), game.get("offerCnt"));

          game.incrementProperty('offerCnt');
          game.get('offers').addObject(newOffer);

          if (+receiver !== 0) {
            receiver.get('receivedOffers').addObject(newOffer);
            receiver.set("hasDirtyAttributes", false);
          }

          if (+sender !== 0) {
            sender.get('sentOffers').addObject(newOffer);
            sender.set("hasDirtyAttributes", false);
          }

          newOffer.save().then(function () {
            if (+sender !== 0) {
              sender.save();
            }
            if (+receiver !== 0) {
              receiver.save();
            }
            game.save();
            return true;
          });
        });
      },

      confirmOffer: function confirmOffer(game, offer) {
        offer.set("isConfirmed", true);
        offer.set("notes", offer.get("notes") + ((0, _moment['default'])().format() + ';confirmed\n'));
        offer.save();

        this.logPlayerOfferWithObj(this.store, game, offer, "confirmed");

        if (offer.get("isExternal")) {
          this.send("acceptOffer", game, offer);
        }
      },

      recallConfirmationOffer: function recallConfirmationOffer(game, offer) {
        offer.set("isConfirmed", false);
        offer.set("notes", offer.get("notes") + ((0, _moment['default'])().format() + ';confirmed\n'));
        offer.save();

        this.logPlayerOfferWithObj(this.store, game, offer, "recalled - confirmed");
      },

      acceptOffer: function acceptOffer(game, offer) {
        var self = this;

        _ember['default'].run(function () {

          // Do some extra check that no negative values occur somehow...

          var receiverTomatoes = -offer.get("receiver.content.tomatoes") + offer.get("tomatoes") > +offer.get("receiver.content.goalTomatoes") && offer.get("receiver.content.isSeller");

          var senderTomatoes = -offer.get("sender.content.tomatoes") + offer.get("tomatoes") > +offer.get("sender.content.goalTomatoes") && offer.get("sender.content.isSeller");

          //console.log(-offer.get("sender.content.tomatoes"), offer.get("tomatoes"), offer.get("sender.content.goalTomatoes"))

          if (receiverTomatoes || senderTomatoes) {
            //console.log("Offer needed to be declined, otherwise seller would have sold more tomatoes than available")
            self.send("declineOffer", game, offer);
            return;
          }

          offer.set("isAccepted", true);
          offer.set("state", "accepted");
          offer.set("notes", offer.get("notes") + ((0, _moment['default'])().format() + ';accepted\n'));

          if (offer.get("receiver.content") !== null) {
            var sign = offer.get("receiver.content.isSeller") ? 1 : -1;
            offer.set("receiver.content.money", +offer.get("receiver.content.money") + sign * offer.get("price") * offer.get("tomatoes"));
            offer.set("receiver.content.tomatoes", +offer.get("receiver.content.tomatoes") - sign * offer.get("tomatoes"));
            offer.set("receiver.content.hasDirtyAttributes", false);
            offer.get("receiver.content").save();
          }

          if (offer.get("sender.content") !== null) {
            var sign = offer.get("sender.content.isSeller") ? 1 : -1;
            offer.set("sender.content.money", +offer.get("sender.content.money") + sign * offer.get("price") * offer.get("tomatoes"));
            offer.set("sender.content.tomatoes", +offer.get("sender.content.tomatoes") - sign * offer.get("tomatoes"));
            offer.set("sender.content.hasDirtyAttributes", false);
            offer.get("sender.content").save();
          }

          offer.save();

          self.logPlayerOfferWithObj(self.store, game, offer, "accepted");
        });
      },

      declineOffer: function declineOffer(game, offer) {
        offer.set("state", "declined");
        offer.set("notes", offer.get("notes") + ((0, _moment['default'])().format() + ';declined\n'));

        this.logPlayerOfferWithObj(this.store, game, offer, "declined");

        return offer.save();
      },

      recallOffer: function recallOffer(game, offer) {
        offer.set("state", "recalled");
        offer.set("notes", offer.get("notes") + ((0, _moment['default'])().format() + ';recalled\n'));
        offer.save();

        this.logPlayerOfferWithObj(this.store, game, offer, "recalled - open");
      }
    }
  });
});