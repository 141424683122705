define('tomato/models/history', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _moment) {
    exports['default'] = _emberDataModel['default'].extend({
        offerId: (0, _emberDataAttr['default'])('string'),
        userSender: (0, _emberDataAttr['default'])('string'), //e.g. Seller1
        userReceiver: (0, _emberDataAttr['default'])('string'), //Buyer1
        state: (0, _emberDataAttr['default'])('string'), //Open, Accepted, Declined could also be represented by color (Yellow: offer sent, Red: offer rejected, etc)
        cssStatus: (0, _emberDataAttr['default'])('string'), //active, success, danger
        offer: (0, _emberDataAttr['default'])('string'), // number of tomatos, price
        tomatoesOffer: (0, _emberDataAttr['default'])('string'),
        priceOffer: (0, _emberDataAttr['default'])('string'),
        round: (0, _emberDataAttr['default'])('string'),
        idxOfOfferInGame: (0, _emberDataAttr['default'])('number'),

        ts: (0, _emberDataAttr['default'])('number', { defaultValue: function defaultValue() {
                return +(0, _moment['default'])();
            } }), //timeStamp

        historyGame: (0, _emberDataRelationships.belongsTo)('game', { async: true }),

        idxOfOfferInGameCalc: _ember['default'].computed("offerId", "historyGame", "historyGame.offers.[]", function () {
            // return "0";

            if (!this.get("offerId")) {
                return "status";
            }

            var gameOffers = this.get("historyGame.offers");

            if (gameOffers === undefined || gameOffers.map === undefined) {
                return "";
            }

            var ids = JSON.parse(sessionStorage["allObjIds"]);
            return ids.indexOf(this.get("offerId")) + 1;
        }),

        tsDesc: _ember['default'].computed('ts', function () {
            return (0, _moment['default'])(this.get("ts")).format("HH:mm:ss");
        })

    });
});

// import { belongsTo, hasMany } from 'ember-data/relationships';

//using descending sort
//offerSortingDescById  : ['offerId:desc'],
//sortedOffersById: Ember.computed.sort('offers', 'offerSortingDesc'),