define('tomato/components/player-ov', ['exports', 'ember', 'tomato/mixins/offer-actions'], function (exports, _ember, _tomatoMixinsOfferActions) {
  exports['default'] = _ember['default'].Component.extend(_tomatoMixinsOfferActions['default'], {
    i18n: _ember['default'].inject.service(),

    actions: {
      saveUser: function saveUser(user) {
        user.save();
      },

      toggleExternalTrading: function toggleExternalTrading(user) {
        user.toggleProperty("enableExternalTrading");
        user.save();

        var newHistoryObj = this.get("store").createRecord('history', {
          offerId: undefined,
          userSender: user.get("descriptivePlayerIdInGameForLogger"),
          userReceiver: "",
          state: "Change to external trade status",
          cssStatus: "",
          offer: user.get("enableExternalTrading") ? "Enabled" : "Disabled",
          round: "Round " + (user.get("userGame.roundCnt") + 1),
          historyGame: user.get("userGame")
        });

        newHistoryObj.save().then(function () {
          return true;
        });
      }
    }
  });
});