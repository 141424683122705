define('tomato/routes/historical', ['exports', 'ember', 'lodash/lodash'], function (exports, _ember, _lodashLodash) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),

    title: function title() {
      return this.get("i18n").t("index.title");
    },

    game: null,

    model: function model(p) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.store.find('game', p.game_id).then(function (game) {
          _this.set('game', game);

          game.get('offers').then(function () {
            return _this.get('store').query('history', {
              orderBy: "historyGame",
              equalTo: p.game_id
            }).then(function (history) {
              var offerIds = history.map(function (x) {
                return x.get("offerId");
              });
              sessionStorage["allObjIds"] = JSON.stringify(_lodashLodash['default'].uniq(offerIds).sort());

              resolve(history);
            });
          });
        });
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('game', this.get('game'));
    }
  });
});