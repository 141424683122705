define("tomato/templates/components/models-table/simple-pagination", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 42
          }
        },
        "moduleName": "tomato/templates/components/models-table/simple-pagination.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "href", "#");
        var el2 = dom.createElement("span");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" \n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "href", "#");
        var el2 = dom.createElement("span");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" \n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "href", "#");
        var el2 = dom.createElement("span");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" \n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "href", "#");
        var el2 = dom.createElement("span");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [0]);
        var element4 = dom.childAt(fragment, [4]);
        var element5 = dom.childAt(element4, [0]);
        var element6 = dom.childAt(fragment, [6]);
        var element7 = dom.childAt(element6, [0]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createAttrMorph(element2, 'class');
        morphs[4] = dom.createElementMorph(element2);
        morphs[5] = dom.createAttrMorph(element3, 'class');
        morphs[6] = dom.createAttrMorph(element4, 'class');
        morphs[7] = dom.createElementMorph(element4);
        morphs[8] = dom.createAttrMorph(element5, 'class');
        morphs[9] = dom.createAttrMorph(element6, 'class');
        morphs[10] = dom.createElementMorph(element6);
        morphs[11] = dom.createAttrMorph(element7, 'class');
        return morphs;
      },
      statements: [["attribute", "class", ["subexpr", "if", [["get", "gotoBackEnabled", ["loc", [null, [1, 46], [1, 61]]]], "enabled", "disabled"], [], ["loc", [null, [1, 41], [1, 84]]]]], ["element", "action", ["gotoFirst"], [], ["loc", [null, [1, 12], [1, 34]]]], ["attribute", "class", ["concat", [["get", "icons.nav-first", ["loc", [null, [2, 13], [2, 28]]]]]]], ["attribute", "class", ["subexpr", "if", [["get", "gotoBackEnabled", ["loc", [null, [3, 45], [3, 60]]]], "enabled", "disabled"], [], ["loc", [null, [3, 40], [3, 83]]]]], ["element", "action", ["gotoPrev"], [], ["loc", [null, [3, 12], [3, 33]]]], ["attribute", "class", ["concat", [["get", "icons.nav-prev", ["loc", [null, [4, 13], [4, 27]]]]]]], ["attribute", "class", ["subexpr", "if", [["get", "gotoForwardEnabled", ["loc", [null, [5, 45], [5, 63]]]], "enabled", "disabled"], [], ["loc", [null, [5, 40], [5, 86]]]]], ["element", "action", ["gotoNext"], [], ["loc", [null, [5, 12], [5, 33]]]], ["attribute", "class", ["concat", [["get", "icons.nav-next", ["loc", [null, [6, 13], [6, 27]]]]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "gotoForwardEnabled", ["loc", [null, [7, 46], [7, 64]]]], "enabled", "disabled"], [], ["loc", [null, [7, 41], [7, 87]]]]]]], ["element", "action", ["gotoLast"], [], ["loc", [null, [7, 12], [7, 33]]]], ["attribute", "class", ["concat", [["get", "icons.nav-last", ["loc", [null, [8, 13], [8, 27]]]]]]]],
      locals: [],
      templates: []
    };
  })());
});