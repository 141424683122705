define("tomato/mixins/log-functions", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Mixin.create({
		logPlayerOfferWithObj: function logPlayerOfferWithObj(store, game, offer, state) {
			var self = this;

			_ember["default"].run(function () {
				var sender = offer.get("sender.content") !== null ? offer.get("sender") : 0;
				var receiver = offer.get("receiver.content") !== null ? offer.get("receiver") : 0;
				var tomatoes = offer.get("tomatoes");
				var price = offer.get("price");
				var offerId = offer.get("id");
				var idxOfOfferInGame = offer.get("idxOfOfferInGame");

				//console.log(idxOfOfferInGame);

				self.logPlayerOffer(store, game, sender, receiver, tomatoes, price, state, offerId, idxOfOfferInGame);
			});
		},

		logPlayerOffer: function logPlayerOffer(store, game, sender, receiver, tomatoes, price, state, offerId, idxOfOfferInGame) {
			_ember["default"].run(function () {

				var stateCssLUT = {
					"open": "active",
					"confirmed": "success",
					"recalled - confirmed": "active",
					"accepted": "success",
					"declined": "danger",
					"recalled - open": "active"
				};

				var newHistoryObj = store.createRecord('history', {
					offerId: offerId,
					userSender: +sender === 0 ? "External" : "" + sender.get('descriptivePlayerIdInGameForLogger'),
					userReceiver: +receiver === 0 ? "External" : "" + receiver.get('descriptivePlayerIdInGameForLogger'),
					state: state,
					cssStatus: stateCssLUT[state],
					offer: "tomatoes: " + tomatoes + ", price: " + price,
					tomatoesOffer: tomatoes,
					idxOfOfferInGame: idxOfOfferInGame,
					priceOffer: price,
					round: "Round " + game.get("roundCnt"),
					historyGame: game
				});

				// game.get('historyLogs').addObject(newHistoryObj);

				newHistoryObj.save();
			});
		}
	});
});