define('tomato/models/offer', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _moment) {
  exports['default'] = _emberDataModel['default'].extend({
    rev: (0, _emberDataAttr['default'])('string'),
    tomatoes: (0, _emberDataAttr['default'])('number'),
    price: (0, _emberDataAttr['default'])('number'),
    isExternal: (0, _emberDataAttr['default'])('boolean'),
    state: (0, _emberDataAttr['default'])('string'), // {'open': <ts>, 'accepted' <ts>}
    isConfirmed: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }), // before offer is 'accepted' it needs to be confirmed by both sides
    isAccepted: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }), // before offer is 'accepted' it needs to be confirmed by both sides

    // notes      : attr('string'),
    ts: (0, _emberDataAttr['default'])('number', { defaultValue: function defaultValue() {
        return +(0, _moment['default'])();
      } }), //timeStamp

    idxOfOfferInGame: (0, _emberDataAttr['default'])('number'),
    roundNumber: (0, _emberDataAttr['default'])('number'),
    weekNumber: (0, _emberDataAttr['default'])('number'),
    game: (0, _emberDataRelationships.belongsTo)('game'),
    receiver: (0, _emberDataRelationships.belongsTo)('user'),
    sender: (0, _emberDataRelationships.belongsTo)('user'),

    // for the race-condition, check if this flag is set, then set the state to accepted no matter what
    isAcceptedWatch: _ember['default'].observer("isAccepted", function () {
      if (this.get("isAccepted")) {
        this.set("state", "accepted");
        this.save();
      }
    }),

    senderName: _ember['default'].computed('sender.name', 'isExternal', function () {
      return this.get('sender.id') === undefined ? "External" : this.get('sender.name');
    }),

    receiverName: _ember['default'].computed('sender.name', 'isExternal', function () {
      return this.get("receiver.id") === undefined ? "External" : this.get('receiver.name');
    }),

    senderId: _ember['default'].computed('sender.id', 'isExternal', function () {
      return this.get('sender.id') === undefined ? "External" : this.get('sender.id');
    }),

    receiverId: _ember['default'].computed('sender.id', 'isExternal', function () {
      return this.get("receiver.id") === undefined ? "External" : this.get('receiver.id');
    }),

    idxOfOfferInGameCalc: _ember['default'].computed("", function () {
      var gameOffers = this.get("game.offers");
      if (gameOffers === undefined || gameOffers.map === undefined) {
        return "";
      }

      return gameOffers.map(function (x) {
        return x.get("id");
      }).indexOf(this.get("id"));
    })

  });
});