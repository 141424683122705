define("tomato/utils/store-with-week", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = storeWithWeek;

  function storeWithWeek(weekCntPath, key) {
    return _ember["default"].computed(weekCntPath, "playerWeekStatus", {
      get: function get() {
        var weekCnt = this.get(weekCntPath);
        var tmpKey = "playerWeekStatus.w" + weekCnt + "." + key;
        return this.get(tmpKey) || 0;
      },
      set: function set() {
        if (!this.get("playerWeekStatus")) {
          this.set("playerWeekStatus", {});
        }

        var val = arguments[1];
        var weekCnt = this.get(weekCntPath);
        var previousObj = this.get("playerWeekStatus");

        //console.debug(previousObj);

        if (!weekCnt) {
          return;
        }

        if (!previousObj["w" + weekCnt]) {
          previousObj["w" + weekCnt] = {};
        }
        previousObj["w" + weekCnt][key] = val;
        //console.debug(previousObj);

        this.set("playerWeekStatus", previousObj);
        return val;
      }
    });
  }
});