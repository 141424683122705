define('tomato/mixins/game-config-parser', ['exports', 'ember', 'lodash/lodash'], function (exports, _ember, _lodashLodash) {

  var fn = function fn(self, tag) {
    return function (round) {
      var cleanLines = self.get("cleanLines");
      var retailPrice = cleanLines.filter(function (x) {
        return x.startsWith(tag);
      })[0];
      return retailPrice.split(",")[round];
    };
  };

  exports['default'] = _ember['default'].Mixin.create({
    i18n: _ember['default'].inject.service(),

    gameConfigurationRO: _ember['default'].computed("gameConfiguration", {
      get: function get() {
        return this.get("gameConfiguration");
      },
      set: function set() {
        var val = arguments[1];
        return val.replace(/\t/g, ",");
      }
    }),

    gameConfigurationValid: _ember['default'].computed("gameConfiguration", function () {
      try {
        this.sanityCheck(this.get("gameConfiguration"));
        return true;
      } catch (err) {
        return false;
      }
    }),

    gameConfigurationSafe: _ember['default'].computed("gameConfiguration", {
      get: function get() {
        return this.get("gameConfiguration");
      },
      set: function set() {
        var val = arguments[1];
        try {
          this.sanityCheck(val);
          this.set("gameConfiguration", val);
          return val;
        } catch (err) {
          alert(err);
        }
      }
    }),

    cleanLines: _ember['default'].computed("gameConfiguration", function () {
      try {
        return this.sanityCheck(this.get("gameConfiguration"));
      } catch (err) {
        return [];
      }
    }),

    numberOfPlayers: _ember['default'].computed("cleanLines", function () {
      var cleanLines = this.get("cleanLines");
      var buyers = cleanLines.filter(function (x) {
        return (/^b\d+\b/.test(x)
        );
      }).map(function (x) {
        return x.split(",")[0];
      });
      var sellers = cleanLines.filter(function (x) {
        return (/^s\d+\b/.test(x)
        );
      }).map(function (x) {
        return x.split(",")[0];
      });
      var allPlayerIds = [].concat(buyers, sellers);

      return { nrOfBuyers: buyers.length, nrOfSellers: sellers.length, allPlayerIds: allPlayerIds };
    }),

    gameMatrix: _ember['default'].computed("cleanLines", "numberOfPlayers", "numberOfRounds", function () {
      var _this = this;

      try {
        var _ret = (function () {
          var cleanLines = _this.get("cleanLines");
          var roundArray = _this.get("numberOfRounds.gameSegments");
          var allPlayerIds = _this.get("numberOfPlayers.allPlayerIds");

          var gameMatrix = roundArray.map(function (x, i) {
            var round = i + 1;
            var tradeType = x.startsWith("w") ? "weekly" : "daily";
            var tradingFor = /\d+/.exec(x)[0];
            var currentWeek = +tradingFor + (x.startsWith("w") ? -1 : 0);

            var roundTitle = "N-A (USE TRANSLATION)"; // `#${round} Week: ${currentWeek}: ${tradeType} for Week ${tradingFor}`;
            // currentWeek tradeType tradeWeek
            var playerSettings = allPlayerIds.map(function (x) {
              return _this.getValueForUserAndRound(cleanLines, x, round);
            });
            return { round: round, roundTitle: roundTitle, playerSettings: playerSettings, tradingFor: tradingFor, tradeType: tradeType, currentWeek: currentWeek };
          });

          //console.log(gameMatrix[0]);
          //console.log(gameMatrix);
          return {
            v: gameMatrix
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      } catch (err) {
        console.warn("There was an error with the game matrix.");
        return [];
      }
    }),

    numberOfRounds: _ember['default'].computed("cleanLines", function () {
      var cleanLines = this.get("cleanLines");

      var gameLine = this.getGameLine(cleanLines);
      var gameSegments = gameLine.split(",");
      gameSegments.shift(); // remove first element
      //console.log(gameSegments);
      var total = gameSegments.length;
      var weeks = gameSegments.filter(function (x) {
        return x.startsWith("w");
      }).length;
      var days = gameSegments.filter(function (x) {
        return x.startsWith("d");
      }).length;
      return { total: total, weeks: weeks, days: days, gameSegments: gameSegments };
    }),

    getGameLine: function getGameLine() {
      var cleanLines = this.get("cleanLines");
      return cleanLines.filter(function (x) {
        return (/^game/.test(x)
        );
      })[0];
    },

    getValueforUserCurrentRound: function getValueforUserCurrentRound(user) {
      var postFix = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

      var cleanLines = this.get("cleanLines");
      var roundCnt = this.get("roundCnt");
      return this.getValueForUserAndRound(cleanLines, user, roundCnt, postFix);
    },

    getValueForUserAndRound: function getValueForUserAndRound(cleanLines, user, round) {
      var postFix = arguments.length <= 3 || arguments[3] === undefined ? '' : arguments[3];

      if (!user) {
        throw "user must exist";
      }
      if (round <= 0) {
        throw "round must be greater 0";
      }

      var userLine = cleanLines.filter(function (x) {
        return x.startsWith('' + user + postFix);
      })[0];
      return userLine.split(",")[round];
    },

    getRetailpriceForRound: function getRetailpriceForRound(round) {
      return fn(this, "retailPrice")(round);
    },
    getSellerFineForRound: function getSellerFineForRound(round) {
      return fn(this, "fine")(round);
    },
    getSellerFixedCostForRound: function getSellerFixedCostForRound(round) {
      return fn(this, "fixedCost")(round);
    },
    getMinutesPerRoundForRound: function getMinutesPerRoundForRound(round) {
      return fn(this, "minutesPerRound")(round);
    },
    getTradingScheduleForRound: function getTradingScheduleForRound(round) {
      return fn(this, "tradingSchedule")(round);
    },

    sanityCheck: function sanityCheck(rawConfigString) {
      var cleanLines = rawConfigString.split("\n").filter(function (x) {
        return (/^(game|b\d+|s\d+|retailPrice|fine|fixedCost|minutesPerRound|tradingSchedule)/.test(x)
        );
      }).map(function (x) {
        return x.replace(/#.*$/, "").replace(/\s+/g, "");
      });

      var gameLinePresent = cleanLines.filter(function (x) {
        return (/^game/.test(x)
        );
      });
      if (gameLinePresent.length !== 1) {
        throw "Please check how to create a game config, game line not found";
      }

      var checkNumbers = cleanLines.map(function (x) {
        return x.split(",").length - 1;
      });
      var sameCommas = checkNumbers.every(function (x) {
        return x === checkNumbers[0];
      });
      if (!sameCommas) {
        throw "The game config has not the same amount of colums in every row, please count <,> in the config again";
      }

      var uniqueUsers = cleanLines.filter(function (x) {
        return (/^(b|s)\d+\b/.test(x)
        );
      }).map(function (x) {
        return x.split(",")[0];
      });

      if (_lodashLodash['default'].uniq(uniqueUsers).length !== uniqueUsers.length) {
        throw "Every user must be unique in the config, two times b1 causes errors";
      }

      return cleanLines;
    }
  });
});