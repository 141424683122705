define('tomato/models/game', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'tomato/mixins/game-config-parser', 'tomato/utils/store-with-week', 'moment', 'lodash/lodash'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _tomatoMixinsGameConfigParser, _tomatoUtilsStoreWithWeek, _moment, _lodashLodash) {

  var rawConfigString = '\ngame            ,      w1 ,      d1 ,      w2 ,      d2\nretailPrice     ,    1.20 ,    1.20 ,    1.10 ,    1.10\nfine            ,    0.20 ,    0.20 ,    0.20 ,    0.20\nfixedCost       ,    0.05 ,    0.05 ,    0.05 ,    0.05\nminutesPerRound ,       7 ,       7 ,       7 ,       7\ntradingSchedule ,     3-5 ,     3-5 ,     3-5 ,     3-5\nb1              , 2400000 , 2400000 , 2750000 , 2750000\nb1_extPrice     ,    1.15 ,    1.25 ,    1.20 ,    1.15\nb1_extTomato    ,  350000  , 500000 ,  900000 ,  350000\nb2              , 2750000 , 2750000 , 1800000 , 1800000\nb2_extPrice     ,    1.30 ,    1.20 ,    1.20 ,    1.20\nb2_extTomato    ,  400000 ,  500000 ,  800000 , 1000000\ns1              , 2500000 , 2500000 , 2000000 , 2000000\ns1_extPrice     ,    1.05 ,    1.05 ,    1.05 ,    1.05\ns1_extTomato    , 2000000 ,  400000 ,  400000 ,  200000\ns2              , 1500000 , 1500000 , 2500000 , 2500000\ns2_extPrice     ,    1.10 ,    1.10 ,    1.10 ,    1.00\ns2_extTomato    ,  900000 ,  550000 ,  900000 ,  300000\n';

  exports['default'] = _emberDataModel['default'].extend(_tomatoMixinsGameConfigParser['default'], {
    i18n: _ember['default'].inject.service(),
    ts: (0, _emberDataAttr['default'])('number', { defaultValue: function defaultValue() {
        return +(0, _moment['default'])();
      } }), //timeStamp
    history: (0, _emberDataAttr['default'])('string'),
    gameConfiguration: (0, _emberDataAttr['default'])('string', { defaultValue: rawConfigString }), // CHECK THE MIXIN, quite some stuff attached there...
    gameName: (0, _emberDataAttr['default'])('string'),

    offerCnt: (0, _emberDataAttr['default'])('number', { defaultValue: 1 }),
    roundCnt: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
    minutesPerRound: (0, _tomatoUtilsStoreWithWeek['default'])("roundCnt", "minutesPerRound"),
    fine: (0, _tomatoUtilsStoreWithWeek['default'])("roundCnt", "fine"),
    fixedCost: (0, _tomatoUtilsStoreWithWeek['default'])("roundCnt", "fixedCost"),
    timeStartTs: (0, _emberDataAttr['default'])('number'),
    timeEndTs: (0, _emberDataAttr['default'])('number'),
    timePausedTs: (0, _emberDataAttr['default'])('number'),
    isNew: (0, _emberDataAttr['default'])('boolean', { defaultValue: true }),

    retailPrice: (0, _tomatoUtilsStoreWithWeek['default'])("roundCnt", "retailPrice"),
    playerWeekStatus: (0, _emberDataAttr['default'])('json', { defaultValue: {} }),

    users: (0, _emberDataRelationships.hasMany)('user', { async: true }),
    offers: (0, _emberDataRelationships.hasMany)('offer', { async: true }),
    // historyLogs:       hasMany('history', { async: true }),

    isImported: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),

    //                                     __           __
    //   _________  ____ ___  ____  __  __/ /____  ____/ /
    //  / ___/ __ \/ __ `__ \/ __ \/ / / / __/ _ \/ __  /
    // / /__/ /_/ / / / / / / /_/ / /_/ / /_/  __/ /_/ /
    // \___/\____/_/ /_/ /_/ .___/\__,_/\__/\___/\__,_/
    //                    /_/

    isPaused: _ember['default'].computed("timePausedTs", function () {
      return this.get("timePausedTs") !== undefined && this.get("timePausedTs") !== null;
    }),

    roundNumber: _ember['default'].computed.alias("roundCnt"),
    weekNumber: _ember['default'].computed.alias("weekCnt"),
    weekCnt: _ember['default'].computed("roundCnt", function () {
      var roundCnt = this.get("roundCnt") - 1;
      if (roundCnt < 0) {
        roundCnt = 0;
      }
      return this.get('gameMatrix.' + roundCnt + '.tradingFor');
    }),

    gameIsAboutToStart: _ember['default'].computed("roundCnt", "numberOfRounds", function () {
      return +this.get("roundCnt") === 0;
    }),

    gameIsRunning: _ember['default'].computed("roundCnt", "numberOfRounds", function () {
      return +this.get("roundCnt") > 0 && this.get("roundCnt") <= this.get("numberOfRounds.total");
    }),

    isLastRound: _ember['default'].computed("roundCnt", "numberOfRounds", function () {
      return +this.get("roundCnt") === this.get("numberOfRounds.total");
    }),

    gameHasEnded: _ember['default'].computed("roundCnt", "numberOfRounds", function () {
      return +this.get("roundCnt") > this.get("numberOfRounds.total");
    }),

    // using descending sort
    offerSortingDesc: ['ts:desc'],
    sortedOffers: _ember['default'].computed.sort('offers', 'offerSortingDesc'),

    //Descending sort to historyLogs
    historyLogsSortingDescById: ['ts:desc'],
    sortedHistoryLogsById: _ember['default'].computed.sort('historyLogs', 'historyLogsSortingDescById'),

    sellers: _ember['default'].computed.filter('users.@each.isSeller', function (el) {
      return el.get("isSeller") === true;
    }),
    buyers: _ember['default'].computed.filter('users.@each.isSeller', function (el) {
      return el.get("isSeller") === false;
    }),

    currentGameSettings: _ember['default'].computed("roundCnt", function () {
      return this.get('gameMatrix.' + (this.get("roundCnt") - 1));
    }),

    allUsers: _ember['default'].computed.filter('users', function () {
      return true;
    }),

    userLUT: _ember['default'].computed('allUsers', function () {
      return _lodashLodash['default'].groupBy(this.get("allUsers"), function (x) {
        return x.get("id");
      });
    })

  });
});