define('tomato/components/player-offer-display', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    offerState: _ember['default'].computed("offer.state", function () {
      var offerState = this.get("offer.state");

      // Guards against a weird bug where sometimes offerState is not yet initialized.
      if (offerState) {
        // Chop the tail end off recalled offers so we can properly localize their state.
        var offerStateParameters = offerState.split(/[ -]+/);

        return offerStateParameters[0];
      } else {
        // This is what otherwise would have been used.
        return undefined;
      }
    })
  });
});