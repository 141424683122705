define('tomato/adapters/application', ['exports', 'emberfire/adapters/firebase'], function (exports, _emberfireAdaptersFirebase) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberfireAdaptersFirebase['default'];
    }
  });

  // export { default } from 'ember-local-storage/adapters/local';

  // export { default } from 'ember-horizon/adapters/horizon';
});