define("tomato/components/player-results", ["exports", "ember", "tomato/mixins/offer-utilities", "tomato/mixins/game-config-parser", "lodash/lodash", "moment"], function (exports, _ember, _tomatoMixinsOfferUtilities, _tomatoMixinsGameConfigParser, _lodashLodash, _moment) {

  // This is used as the configuration when no data has yet been loaded. Since there will be no players, it won't actually be used.
  var emptyConfiguration = "\ngame            , w1 , d1\nretailPrice     ,  0 ,  0\nfine            ,  0 ,  0\nfixedCost       ,  0 ,  0\nminutesPerRound ,  0 ,  0\nb1              ,  0 ,  0\nb1_extPrice     ,  0 ,  0\nb1_extTomato    ,  0 ,  0\ns1              ,  0 ,  0\ns1_extPrice     ,  0 ,  0\ns1_extTomato    ,  0 ,  0\n";

  exports["default"] = _ember["default"].Component.extend(_tomatoMixinsOfferUtilities["default"], {
    store: _ember["default"].inject.service(),

    // These are used in the child components. We retrieve all the results from the histories for backwards compatibility.
    histories: [],

    // These are used to determine the child components.
    buyers: [],
    sellers: [],

    // These are used to determine the results.
    players: [],
    positions: [],

    loadHistories: _ember["default"].on("init", _ember["default"].observer("game.gameHasEnded", "game.isImported", function () {
      var _this = this;

      if (this.get("game.gameHasEnded") || this.get("game.isImported")) {
        (function () {
          // Load the data only when the game ends (or is imported).
          var self = _this;

          var historyQuery = _this.get('store').query('history', {
            orderBy: "historyGame",
            equalTo: _this.get("game.id")
          });

          historyQuery.then(function (histories) {
            // Duplicate the array before sorting it since sorting is in place and the provided array is not mutable.
            self.set("histories", histories.slice().sort(function (history1, history2) {
              return (0, _moment["default"])(history1.get("ts")).diff((0, _moment["default"])(history2.get("ts")));
            }));
          });
        })();
      } else {
        // Reset the data when we go back in time.
        this.set("histories", []);
      }
    })),

    loadPlayers: _ember["default"].observer("histories.[]", "histories.@each", function () {
      var histories = this.get("histories");

      var offers = histories.filter(function (history) {
        return this.isOfferState(history.get("state"));
      }, this);

      var players = [];

      var filterOffers = function filterOffers(offers, type) {
        offers.forEach(function (offer) {
          var parameters = offer.get(type).split(/[ -]+/);

          var role = parameters[0];
          var position = parameters[1];
          var name = parameters[2];

          // Skip external offers.
          if (!this.isOfferExternalUser(role)) {
            players.pushObject(_ember["default"].Object.create({
              // Mimic the original player model so we can reuse templates.
              roleDescription: role,
              playerPosition: parseInt(position),
              name: name,
              isSeller: this.isOfferSellerUser(role)
            }));
          }
        }, this);
      };

      filterOffers.call(this, offers, "userSender");
      filterOffers.call(this, offers, "userReceiver");

      var buyers = [];
      var sellers = [];

      var filterPlayers = function filterPlayers(role, subjects) {
        players.forEach(function (player) {
          if (player.get("roleDescription") === role) {
            var hasSubject = subjects.any(function (subject) {
              return subject.get("playerPosition") === player.get("playerPosition");
            });

            if (!hasSubject) {
              subjects.pushObject(player);
            }
          }
        });
      };

      filterPlayers("buyer", buyers);
      filterPlayers("seller", sellers);

      // sortBy doesn't appear to work.
      var sortPlayers = function sortPlayers(player1, player2) {
        return player1.get("playerPosition") - player2.get("playerPosition");
      };

      buyers.sort(sortPlayers);
      sellers.sort(sortPlayers);

      this.set("buyers", buyers);
      this.set("sellers", sellers);

      // This mimics the order in the game configuration.
      players = buyers.concat(sellers);

      var positions = players.map(function (player, index) {
        return index + 1;
      });

      this.set("players", players);
      this.set("positions", positions);
    }),

    positionsForPlayers: _ember["default"].computed("players", function () {
      var positionsForPlayers = [];

      var players = this.get("players");

      positionsForPlayers["buyer"] = [];
      positionsForPlayers["seller"] = [];

      players.forEach(function (player, index) {
        var role = player.get("roleDescription");
        var position = player.get("playerPosition");

        positionsForPlayers[role][position] = index + 1;
      });

      return positionsForPlayers;
    }),

    gameConfigParser: _ember["default"].computed("histories.[]", "histories.@each", function () {
      var history = this.get("histories").find(function (history) {
        return history.get("state") === "New Config loaded";
      });

      // Return an empty configuration if the histories have not yet loaded. Since there will be no players, it won't be used.
      var gameConfigurationSafe = history ? history.get("offer") : emptyConfiguration;

      var gameConfigParser = _ember["default"].Object.extend(_tomatoMixinsGameConfigParser["default"]).create({
        gameConfigurationSafe: gameConfigurationSafe
      });

      return gameConfigParser;
    }),

    configuration: _ember["default"].computed("gameConfigParser", function () {
      return this.get("gameConfigParser.gameMatrix");
    }),

    weeks: _ember["default"].computed("configuration", function () {
      var configuration = this.get("configuration");

      var weeks = configuration.map(function (round) {
        return parseInt(round.tradingFor);
      });

      return _lodashLodash["default"].uniq(weeks).sort(function (value1, value2) {
        return value1 - value2;
      });
    }),

    weeksForRounds: _ember["default"].computed("configuration", function () {
      var configuration = this.get("configuration");

      var weeksForRounds = [];

      configuration.forEach(function (roundConfiguration) {
        var round = parseInt(roundConfiguration.round);

        weeksForRounds[round] = parseInt(roundConfiguration.tradingFor);
      });

      return weeksForRounds;
    }),

    typesForRounds: _ember["default"].computed("configuration", function () {
      var configuration = this.get("configuration");

      var typesForRounds = [];

      configuration.forEach(function (roundConfiguration) {
        var round = parseInt(roundConfiguration.round);

        typesForRounds[round] = roundConfiguration.tradeType;
      });

      return typesForRounds;
    }),

    buyerPropertiesPerWeek: _ember["default"].computed("gameConfigParser", "weeks", "weeksForRounds", function () {
      var gameConfigParser = this.get("gameConfigParser");

      var weeks = this.get("weeks");
      var weeksForRounds = this.get("weeksForRounds");

      var buyerPropertiesPerWeek = [];

      weeks.forEach(function (week) {
        buyerPropertiesPerWeek[week] = _ember["default"].Object.create({
          fine: 0.0,
          fixedCosts: 0.0,
          retailPrice: 0.0
        });
      });

      var numberOfRounds = gameConfigParser.get("numberOfRounds").total;

      for (var round = 1; round < numberOfRounds + 1; round++) {
        var week = weeksForRounds[round];

        buyerPropertiesPerWeek[week] = _ember["default"].Object.create({
          fine: gameConfigParser.getSellerFineForRound(round),
          fixedCosts: gameConfigParser.getSellerFixedCostForRound(round),
          retailPrice: gameConfigParser.getRetailpriceForRound(round)
        });
      }

      return buyerPropertiesPerWeek;
    }),

    goalsPerPlayerPerWeek: _ember["default"].computed("configuration", function () {
      var configuration = this.get("configuration");

      var goalsPerPlayerPerWeek = [];

      configuration.forEach(function (roundConfiguration) {
        var week = parseInt(roundConfiguration.tradingFor);

        var playerSettings = roundConfiguration.playerSettings;

        var goalsPerPlayer = [];

        playerSettings.forEach(function (goal, index) {
          var position = index + 1;

          goalsPerPlayer[position] = parseFloat(goal);
        });

        goalsPerPlayerPerWeek[week] = goalsPerPlayer;
      });

      return goalsPerPlayerPerWeek;
    }),

    prognoses: _ember["default"].computed("histories.[]", "histories.@each", function () {
      var prognoses = [];

      this.get("histories").forEach(function (history) {
        var sender = history.get("userSender");

        var prefix = "Prognosis modification to ";

        // Don't look at me, I'm hideous!
        if (sender.startsWith(prefix)) {
          var user = sender.slice(prefix.length);
          var userParameters = user.split(/[ -]+/);

          var role = userParameters[0];
          var position = parseInt(userParameters[1]);

          var percentageParameters = history.get("offer").match(/by (-?\d+)%/);
          var percentage = parseInt(percentageParameters[1]);

          var roundParameters = history.get("round").split(/ /);
          var round = parseInt(roundParameters[1]);

          var prognosis = _ember["default"].Object.create({
            role: role,
            position: position,
            percentage: percentage,
            round: round
          });

          prognoses.pushObject(prognosis);
        }
      }, this);

      return prognoses;
    }),

    prognosesPerWeek: _ember["default"].computed("prognoses", "weeks", "weeksForRounds", function () {
      var prognoses = this.get("prognoses");
      var weeks = this.get("weeks");
      var weeksForRounds = this.get("weeksForRounds");

      var prognosesPerWeek = [];

      weeks.forEach(function (week) {
        prognosesPerWeek[week] = [];
      });

      prognoses.forEach(function (prognosis) {
        var week = weeksForRounds[prognosis.get("round")];

        prognosesPerWeek[week].pushObject(prognosis);
      });

      return prognosesPerWeek;
    }),

    prognosesPerPlayerPerWeek: _ember["default"].computed("prognosesPerWeek", "weeks", "positions", "positionsForPlayers", function () {
      var prognosesPerWeek = this.get("prognosesPerWeek");

      var weeks = this.get("weeks");
      var positions = this.get("positions");

      var positionsForPlayers = this.get("positionsForPlayers");

      var prognosesPerPlayerPerWeek = [];

      weeks.forEach(function (week) {
        var prognoses = prognosesPerWeek[week];

        var prognosesPerPlayer = [];

        positions.forEach(function (position) {
          prognosesPerPlayer[position] = 1.0;
        });

        prognoses.forEach(function (prognosis) {
          var role = prognosis.get("role");
          var position = prognosis.get("position");

          position = positionsForPlayers[role][position];

          // We no longer need the whole object now, just the percentage.
          prognosesPerPlayer[position] = 1.0 + 0.01 * prognosis.get("percentage");
        }, this);

        prognosesPerPlayerPerWeek[week] = prognosesPerPlayer;
      }, this);

      return prognosesPerPlayerPerWeek;
    }),

    offers: _ember["default"].computed("histories.[]", "histories.@each", function () {
      return this.get("histories").filter(function (history) {
        var state = history.get("state");

        return this.isOfferAcceptedState(state);
      }, this);
    }),

    offersPerWeek: _ember["default"].computed("offers", "weeks", "weeksForRounds", function () {
      var offers = this.get("offers");
      var weeks = this.get("weeks");
      var weeksForRounds = this.get("weeksForRounds");

      var offersPerWeek = [];

      weeks.forEach(function (week) {
        offersPerWeek[week] = [];
      });

      offers.forEach(function (offer) {
        var roundParameters = offer.get("round").split(/ /);
        var round = parseInt(roundParameters[1]);

        var week = weeksForRounds[round];

        offersPerWeek[week].pushObject(offer);
      });

      return offersPerWeek;
    }),

    offersPerPlayerPerWeek: _ember["default"].computed("offersPerWeek", "weeks", "positions", "positionsForPlayers", function () {
      var offersPerWeek = this.get("offersPerWeek");

      var weeks = this.get("weeks");
      var positions = this.get("positions");

      var positionsForPlayers = this.get("positionsForPlayers");

      var offersPerPlayerPerWeek = [];

      weeks.forEach(function (week) {
        var offers = offersPerWeek[week];

        var offersPerPlayer = [];

        positions.forEach(function (position) {
          offersPerPlayer[position] = [];
        });

        offers.forEach(function (offer) {
          var senderParameters = offer.get("userSender").split(/[ -]+/);
          var receiverParameters = offer.get("userReceiver").split(/[ -]+/);

          var senderRole = senderParameters[0];
          var senderPosition = parseInt(senderParameters[1]);

          var receiverRole = receiverParameters[0];
          var receiverPosition = parseInt(receiverParameters[1]);

          if (!this.isOfferExternalUser(senderRole)) {
            senderPosition = positionsForPlayers[senderRole][senderPosition];

            offersPerPlayer[senderPosition].pushObject(offer);
          }

          if (!this.isOfferExternalUser(receiverRole)) {
            receiverPosition = positionsForPlayers[receiverRole][receiverPosition];

            offersPerPlayer[receiverPosition].pushObject(offer);
          }
        }, this);

        offersPerPlayerPerWeek[week] = offersPerPlayer;
      }, this);

      return offersPerPlayerPerWeek;
    }),

    volumePerPlayerPerWeek: _ember["default"].computed("offersPerPlayerPerWeek", "weeks", "positions", function () {
      var offersPerPlayerPerWeek = this.get("offersPerPlayerPerWeek");

      var weeks = this.get("weeks");
      var positions = this.get("positions");

      var volumePerPlayerPerWeek = [];

      weeks.forEach(function (week) {
        var offersPerPlayer = offersPerPlayerPerWeek[week];

        var volumePerPlayer = [];

        positions.forEach(function (position) {
          var offers = offersPerPlayer[position];

          var volume = offers.reduce(function (volume, offer) {
            var offerParameters = offer.get("offer").split(/, |:/);

            var amount = parseFloat(offerParameters[1]);

            return volume + amount;
          }, 0.0);

          volumePerPlayer[position] = volume;
        });

        volumePerPlayerPerWeek[week] = volumePerPlayer;
      });

      return volumePerPlayerPerWeek;
    }),

    revenuePerPlayerPerWeek: _ember["default"].computed("offersPerPlayerPerWeek", "weeks", "positions", function () {
      var offersPerPlayerPerWeek = this.get("offersPerPlayerPerWeek");

      var weeks = this.get("weeks");
      var positions = this.get("positions");

      var revenuePerPlayerPerWeek = [];

      weeks.forEach(function (week) {
        var offersPerPlayer = offersPerPlayerPerWeek[week];

        var revenuePerPlayer = [];

        positions.forEach(function (position) {
          var offers = offersPerPlayer[position];

          var revenue = offers.reduce(function (revenue, offer) {
            var offerParameters = offer.get("offer").split(/, |:/);

            var volume = parseFloat(offerParameters[1]);
            var unitPrice = parseFloat(offerParameters[3]);

            return revenue + volume * unitPrice;
          }, 0.0);

          revenuePerPlayer[position] = revenue;
        });

        revenuePerPlayerPerWeek[week] = revenuePerPlayer;
      });

      return revenuePerPlayerPerWeek;
    }),

    kpiPerPlayerPerWeek: _ember["default"].computed("buyerPropertiesPerWeek", "goalsPerPlayerPerWeek", "prognosesPerPlayerPerWeek", "volumePerPlayerPerWeek", "revenuePerPlayerPerWeek", "weeks", "players", "positions", function () {
      var buyerPropertiesPerWeek = this.get("buyerPropertiesPerWeek");
      var goalsPerPlayerPerWeek = this.get("goalsPerPlayerPerWeek");
      var prognosesPerPlayerPerWeek = this.get("prognosesPerPlayerPerWeek");
      var volumePerPlayerPerWeek = this.get("volumePerPlayerPerWeek");
      var revenuePerPlayerPerWeek = this.get("revenuePerPlayerPerWeek");

      var weeks = this.get("weeks");
      var players = this.get("players");
      var positions = this.get("positions");

      var kpiPerPlayerPerWeek = [];

      weeks.forEach(function (week) {
        var buyerProperties = buyerPropertiesPerWeek[week];
        var goalsPerPlayer = goalsPerPlayerPerWeek[week];
        var prognosesPerPlayer = prognosesPerPlayerPerWeek[week];
        var volumePerPlayer = volumePerPlayerPerWeek[week];
        var revenuePerPlayer = revenuePerPlayerPerWeek[week];

        var kpiPerPlayer = [];

        positions.forEach(function (position) {
          var fine = buyerProperties.get("fine");
          var fixedCosts = buyerProperties.get("fixedCosts");
          var retailPrice = buyerProperties.get("retailPrice");

          var goal = goalsPerPlayer[position];
          var prognosis = prognosesPerPlayer[position];
          var volume = volumePerPlayer[position];
          var revenue = revenuePerPlayer[position];

          goal *= prognosis;

          // Players is a zero indexed array.
          if (players[position - 1].get("isSeller")) {
            // The KPI for cooperatives is the unit price.
            kpiPerPlayer[position] = revenue / goal;
          } else {
            var deficit = Math.max(0.0, goal - volume);

            var totalFine = fine * deficit;
            var totalFixedCosts = goal * fixedCosts;

            // Revenue is actually expenditure, in this case.
            var totalCosts = revenue + totalFine + totalFixedCosts;

            var amount = Math.min(goal, volume);
            var income = amount * retailPrice;
            var profit = income - totalCosts;

            // The KPI for traders is the unit profit.
            kpiPerPlayer[position] = profit / goal;
          }
        });

        kpiPerPlayerPerWeek[week] = kpiPerPlayer;
      });

      return kpiPerPlayerPerWeek;
    }),

    averageKPIPerPlayer: _ember["default"].computed("kpiPerPlayerPerWeek", "weeks", "positions", function () {
      var kpiPerPlayerPerWeek = this.get("kpiPerPlayerPerWeek");

      var weeks = this.get("weeks");
      var positions = this.get("positions");

      var averageKPIPerPlayer = [];

      positions.forEach(function (position) {
        var total = kpiPerPlayerPerWeek.reduce(function (total, kpiPerPlayer) {
          return total + kpiPerPlayer[position];
        }, 0.0);

        averageKPIPerPlayer[position] = total / weeks.length;
      });

      return averageKPIPerPlayer;
    })
  });
});