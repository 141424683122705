define("tomato/routes/index", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    i18n: _ember["default"].inject.service(),

    title: function title() {
      return this.get("i18n").t("index.title");
    },

    model: function model() {
      return this.store.findAll('game');
    }
  });
});